import React, { useEffect, useState } from "react"
import { commafy, findDistanceUnits } from "../../utils/helpers";

const MIN_VALUE = '5';
const MAX = '2500';
const ES_MAX = '100000' // magic 'Any Value' number

/** Currently, this is used specifically for the 'Distance' filter. 
 * _Notes_: Attempted to use the `searchkit` `RangeSliderInput` filter, but
 *  * 2x input nodes for `min` & `max` values were not needed, the query is a single _distance from_
 *  * Distance does not use the active SearchKit instance, usting an API (_redux_) call instead
 *   - No field or facet associated because the base is relative and calculated on request.
 * 
 * @param {{
 *  isDisabled?: boolean, 
 *  marketplaceFeatures?: {[key:string]: any, features: string[]},
 *  maxValue?: string, 
 *  nextValue?: string,
 *  onFinished: ()=>void,
 *  showLabel: boolean, 
 *  style: React.CSSProperties, 
 *  step?: string}} props 
 * @returns {React.JSX} `RangeSliderInputCustom`
 */
export function RangeSliderInputCustom (props) {
    /* State */
    const {maxValue=MAX, nextValue} = props;
    const [activeSelectionValue, setActiveSelectionValue] = useState(maxValue || MAX);
    /* Effects */
    useEffect(() => {
        if (nextValue && ![ES_MAX].includes(nextValue)) {
            setActiveSelectionValue(nextValue)
        }
    }, [nextValue, maxValue])
    /* Callbacks */
    /** `onChange` callback - set local state value of input
     * @param {React.ChangeEvent<HTMLInputElement>} event 
     */
    const handleActiveSelection = event => {
        event.stopPropagation();
        setActiveSelectionValue(event.currentTarget.value);
    }
    /** `onChange` callback - set local state value of input
     * @param {React.ChangeEvent<HTMLInputElement>} event 
     */
    const handleSelectionComplete = event => {
        event.stopPropagation();
        let newValue = event.currentTarget.value;
        if (newValue === maxValue) {
            newValue = ES_MAX; 
        }
        props.onFinished(newValue);
    }
    const formatLabelValue = (value, isShortValue=false) => {
        const unitsLongForms = {'km': 'kilometers', 'mi': 'miles'}
        const units = findDistanceUnits(props.marketplaceFeatures);
        return (value === maxValue)
            ? isShortValue
                ? 'All'
                : 'Any Distance'
            : `${commafy(value)} ${isShortValue ? units : unitsLongForms[units] || units}`;
    }

    return (
        <>
            {props.showLabel
                ? (
                    <label style={{width: '100%', textAlign: 'center'}}>
                        {formatLabelValue(activeSelectionValue)}
                    </label>)
                : null
            }
            <span style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 5}}>
                <label style={sideLabelStyle}>{formatLabelValue(MIN_VALUE, true)}</label>
                <input
                    className="srp-slider__custom"
                    disabled={props.isDisabled}
                    min={MIN_VALUE}
                    max={maxValue}
                    onTouchEnd={handleSelectionComplete}
                    onMouseUp={handleSelectionComplete}
                    onChange={handleActiveSelection}
                    step={'5'}
                    style={props.style || {}}
                    type={'range'}
                    value={activeSelectionValue}
                    aria-valuenow={activeSelectionValue}
                    aria-valuetext={`${activeSelectionValue}`}
                />
                <label style={sideLabelStyle}>{formatLabelValue(maxValue, true)}</label>
            </span>
        </>
    )
}

/** Min and Max labels
 * @type {React.CSSProperties}
 */
const sideLabelStyle = {
    color: 'lightgray',
    fontSize: 12,
    textAlign: 'center',
    verticalAlign: 'middle',
    padding: 0,
}

/* searchkit RangeSliderInput props 
  RangeSliderInputCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.any),
    disabled: PropTypes.bool,
    mod: PropTypes.string,
    className: PropTypes.string,
    translate: PropTypes.func,
    showHistogram: PropTypes.bool,
    showSlider: PropTypes.bool,
    showInput: PropTypes.bool,
} */