import React, { Component } from 'react';
import { getConfig } from '../utils/helpers';
import moment from 'moment-timezone';
import jstz from 'jstz';
import { values } from 'lodash';
import { Link } from 'react-router-dom';
import { loadReadAllNotifications, loadReadNotification, loadUnReadNotification } from '../actions';
import { alertTypes } from '../utils/constants';

const timezone = jstz.determine().name();

class NotificationsDropdownItem extends Component {
  state = {
    userId: '',
    notificationIds: [],
  };

  /** Update to `markAsRead: true | false` depending on read state
   * 
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event 
   * @param {{
   *  markAsRead: boolean, 
   *  itemId: number,
   *  receiverUserId: number,
   *  [key:string]: any}} item 
   */
  markAsReadOrUnread = async (event, item) => {
    event.stopPropagation();
    try {
      if (item) {
        const {markAsRead, notificationId, receiverUserId} = item;
        const mpId = getConfig('marketplaceId');
        markAsRead
          ? await this.props.loadUnReadNotification(mpId, notificationId, receiverUserId)
          : await this.props.loadReadNotification(mpId, notificationId, receiverUserId);
        await this.props.loadNotifications(mpId);
      } else {
        return;
      }
    } catch (err) {
      console.error(' Notification Read Error: ', err?.message || err)
    }
  }

  markAllRead = (event) => {
    event.preventDefault();
    try {
      const notificationIds = this.props.notificationItems.map(item => item.notificationId)
      const mpId = getConfig('marketplaceId');
      const userid = this.props.notificationItems[0]?.receiverUserId;
      if (userid) {
        loadReadAllNotifications(mpId, notificationIds, userid)
        .then(res => this.props.loadNotifications(mpId))
        .catch(err => console.error('Error: ', err?.message || err));
      }

    } catch (err) {
      console.error('Error: Notification Read All - ', err?.message || err);
    }
  };

  renderNotificationItems() {
    const {notificationItems} = this.props;

    if (notificationItems.length < 1) {
      return (
        <div className="notificationItem unread">
          <div className="notificationItemSubject clearfix">
            <div className="col-xs-12 no-padding">
              <p>
                There are currently no messages. Subscribe by going to your{' '}
                <Link to="/profile">profile</Link>.
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      const items = values(notificationItems).map((item, index) => {
        const timeDifference = moment
          .tz(`${item.dateCreated}`, timezone)
          .fromNow();

        const notificationTitle = alertTypes.find(i => i.id === item.notificationTypeId)?.notificationTitle

        if (item.notificationTypeId !== 1030 && item.markAsRead === false) {
          return (
            <div
              className="notificationDataId"
              data-id={item.notificationId}
              key={index}
            >
              <div className="notificationItem unread" title={"Mark as 'Read'"}>
                <div className="notificationItemSubject clearfix">
                  <div className="col-xs-1" style={{ paddingTop: '2px' }}>
                    <span
                      className="fas fa-circle"
                      style={{ color: 'rgb(92, 190, 127)' }}
                    >
                      {' '}
                    </span>
                  </div>
                  <div className="col-xs-11">
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                      <span>
                        <div
                          onClick={event => this.markAsReadOrUnread(event, item)}
                        >
                          {notificationTitle}
                        </div>
                      </span>
                      <label
                        style={{
                          color: 'gray',
                          fontSize: '11px',
                          margin: '0 0 0 9.5em',
                        }}
                      >
                        {timeDifference}
                      </label>
                    </div>
                    <div style={{ color: 'gray', padding: '5px 0px' }}>{item.subject}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="notificationWithoutDataId" key={index}>
              <div className="notificationItem read">
                <div className="notificationItemSubject clearfix">
                  <div className="col-xs-1" style={{ paddingTop: '2px' }}>
                    <span
                      className="far fa-circle"
                      style={{ color: 'rgb(92, 190, 127)' }}
                    >
                      {' '}
                    </span>
                  </div>
                  <div className="col-xs-11">
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                      <span>
                        <Link to="/notifications" className="notification-type">
                          {notificationTitle}
                        </Link>
                      </span>
                      <span
                        style={{
                          color: 'gray',
                          fontSize: '11px',
                          margin: '0 0 0 9.5em',
                        }}
                      >
                        {timeDifference}
                      </span>{' '}
                    </div>
                    <div style={{ color: 'gray', padding: '5px 0px' }}>{item.subject}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      });

      return items;
    }
  }

  render() {
    return (
      <div className="dropdown-menu dropdown-menu-right notificationsDDMenu">
        <div className="notifications-panel panel panel-primary">
          <div className="panel-heading">
            <h3 className="panel-title text-uppercase">
              <span className="panel-title-text">Recent Notifications</span>
              <Link to="/profile/alerts">
                <span
                  className="fas fa-cog"
                  style={{ float: 'right', color: 'white' }}
                >
                  {' '}
                </span>
              </Link>
            </h3>
          </div>
          <div className="panel-body notificationItems">
            {this.renderNotificationItems()}
          </div>
          <div className="panel-footer no-padding">
            <div className="col-xs-6 no-padding">
              <Link
                to="#"
                type="button"
                className="btn btn-default btn-block text-uppercase markAllReadBtn"
                onClick={this.markAllRead}
              >
                Clear List
              </Link>
            </div>
            <div className="col-xs-6 no-padding">
              <Link
                to="/notifications"
                type="button"
                className="btn btn-default btn-block text-uppercase viewAllBtn"
              >
                All Notifications
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationsDropdownItem;
