import React from "react";

/**
 * @typedef {Object} StratosLoaderDataProps
 * @property {ItemData  &{[key:string]: any}} itemData
 * @property {string|undefined} darwinDealerId
 */
/** @type {StratosLoaderDataProps} */

/** Invisible `div` containing meta data for Darwin Bookout.
 * Requires the vehicle's VIN, image, and mileage - from corresponding AWG or ES value.
 * @param {StratosLoaderDataProps} props
 * @returns {React.JSX.Element|null}
 */
const StratosLoaderData = ({itemData, darwinDealerId=''}) => {
    if (itemData && 
        (itemData.vin || itemData.vIN) && 
        (itemData.mileage || itemData.odometer) &&
        (itemData.imageFullUrls || itemData.mainImage)
    ) {
      const {vin, vIN, odometer, mileage, imageFullUrls, mainImage=''} = itemData;
      const vehicleImage = imageFullUrls?.length ? imageFullUrls[0] : mainImage;
      return (
        <div 
          style={{width: 0}}
          className="darwinVehicleData"
          data-vin={vin || vIN}
          data-mileage={odometer || mileage}
          data-img={vehicleImage}
          data-darwindealerid={darwinDealerId || ''}
        />
      )
    } else {
      return null;
    }
  }

/**
 * @typedef {Object} ItemData
 * @property {string|undefined} vin - VIN of vehicle (_AWG_)
 * @property {string|undefined} vIN - VIN of vehicle (_Elastic Search_)
 * @property {number|undefined} odometer Odometer reading (_AWG_)
 * @property {number|undefined} mileage Odometer reading (_Elastic Search_)
 * @property {string[] | undefined} imageFullUrls array of image URL strings (AWG_)
 * @property {string|undefined} mainImage image url string (_Elastic Search)
 */
/** @type {ItemData} */

export {StratosLoaderData};