import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Label } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import NotificationsDropdownItem from '../components/NotificationsDropdownItem';
import { loadNotifications } from '../actions';
import { getConfig } from '../utils/helpers';

class NotificationsDropdown extends Component {
  componentDidMount() {
    this.props.loadNotifications(getConfig('marketplaceId'));
  }

  notifyClickHandler = () => {
    $('.notificationWrapper .dropdown-toggle').dropdown();
  };

  renderNoticationCount() {
    const notificationsCount = (this.props.notifications.items || []).reduce(
      (count, item) => {
        return item.notificationTypeId !== 1030 && item.markAsRead === false
          ? count + 1
          : count;
      },
      0
    );

    return notificationsCount > 0 ? <Label>{notificationsCount}</Label> : null;
  }

  render() {
    const baseClass = this.props.baseClass
      ? 'notificationsDD ' + this.props.baseClass
      : 'notificationsDD';

    return (
      <div className={baseClass + ' notificationWrapper'}>
        <ul className="nav navbar-nav pull-left user-nav">
          <li className="">
            <Link
              to="#"
              className="notificationIconLink"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={this.notifyClickHandler}
            >
              <span className="fa fa-bell notificationIcon">
                {this.renderNoticationCount()}
              </span>
            </Link>
            <NotificationsDropdownItem
              notificationItems={this.props.notifications.items || []}
              loadNotifications={this.props.loadNotifications}
            />
          </li>
        </ul>
      </div>
    );
  }
}

NotificationsDropdown.defaultProps = {
  notifications: {},
};

NotificationsDropdown.propTypes = {
  baseClass: PropTypes.string,
  loadNotifications: PropTypes.func.isRequired,
  notifications: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { notifications } = state.entities;
  return { notifications };
};

export default connect(
  mapStateToProps,
  { loadNotifications }
)(NotificationsDropdown);
