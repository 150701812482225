/**
 * @description Unused at the moment, but can be used to
 *  conditionally load freshchat widget or pass in specific params 
 *  (_i.e. 'token', 'host', etc._)
 */
export const freshChatWidgetInit = () => {
  /**
   * 
   *  Note:  Although domain-whitelisted, `init()` settings should be passed from mpConfig
   * */
  function initFreshChat() {
    window.fcWidget.init({
      token: "32c11575-50a2-43c7-a6ec-5fc297ef78d1",
      host: "https://superior-ia.freshchat.com",
      // siteId: "" - separate MP's if needed
    });
  }

  /** `initialize` _local function_. Add script `src` to `head`
   * 
   * @param {Document} i document instance
   * @param {string} t id of element
   */
  function initialize(i,t){
      var e;
      
      if (i.getElementById(t) || window.fcWidget) {
          initFreshChat();

      } else {
          e=i.createElement("script");
          e.id = t;
          e.type='text/javascript';
          e.async=!0;
          e.src="https://superior-ia.freshchat.com/js/widget.js";
          e.onload=initFreshChat;
          i.head.appendChild(e);
      }
  }

  function initiateCall () {
      initialize(document,"Freshchat-js-sdk")
  }

  /* Initialize or add EventListener to DOM */
  document.readyState === "complete"
    ? initiateCall()
    : window.addEventListener
    ? window.addEventListener("load",initiateCall,!1)
    : window.attachEvent("load",initiateCall,!1)
}

/* Setting user info
window.fcWidget.user.setProperties({
    firstName: "John",              // user's first name
    lastName: "Doe",                // user's last name
    email: "john.doe@gmail.com",    // user's email address
    phone: "8668323090",            // phone number without country code
    phoneCountryCode: "+1",         // phone's country code
    plan: "Estate",                 // user's meta property 1
    status: "Active",               // user's meta property 2
    "Last Payment": "12th August"   // user's meta property 3
  });
*/ 

/* 
 --- Original ---
 <script>
    function initFreshChat() {
      window.fcWidget.init({
        token: "32c11575-50a2-43c7-a6ec-5fc297ef78d1",
        host: "https://superior-ia.freshchat.com"
      });
    }
    function initialize(i,t){var e;i.getElementById(t)?
    initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,
    e.src="https://superior-ia.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}
    function initiateCall(){initialize(document,"Freshchat-js-sdk")}
    window.addEventListener?window.addEventListener("load",initiateCall,!1):
    window.attachEvent("load",initiateCall,!1);
  </script>
------------------
*/