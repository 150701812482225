import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableButtonCSV from './TableButtonCSV';
import TableButtonPrint from './TableButtonPrint';
import TableButtonWatchlist from './TableButtonWatchlist';

const defaultButton = props => <button {...props}>{props.children}</button>;

class TablePaginationAndButtons extends Component {
  state = {
    visiblePages: [],
  };

  componentDidMount() {
    this.setState({
      visiblePages: this.getVisiblePages(null, this.props.pages),
    });

    if (this.props.page) {
      this.props.onPageChange(this.props.page);
    }
  }

  /* TODO: 2025.01 --
    - componentWillReceiveProps is deprecated.
    - this method handles 'show pages' update (e.g. '10' -> '50' rows)
      and does not account for 'active' page.
  */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages),
      });
    }

    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  handleBreadcrumbs(page) {
    // the navbar handles almost all the breadcrumbs, however isn't aware of any pagination changes
    // so we have to do a little work here to update the breadcrumbs with the pagination info
    // we also change the url manually but this is for visual purposes only
    const url = new URL(window.location);
    url.searchParams.set('p', page);
    window.history.pushState(null, '', url);

    const bcCrumbs = sessionStorage.getItem('bcCrumbs');
    const existingBreadcrumbs = bcCrumbs
      ? JSON.parse(bcCrumbs)
      : { path: url.pathname, crumbs: [] };
    const newBreadcrumbs = {
      path: url.pathname,
      crumbs: [
        {
          text: existingBreadcrumbs.crumbs[0].text,
          link: url.pathname + url.search,
        },
      ],
    };
    sessionStorage.setItem('bcCrumbs', JSON.stringify(newBreadcrumbs));
  }

  changePage = page => {
    const activePage = this.props.page + 1;

    if (page === activePage) {
      return;
    }

    window.scrollTo(0, 0);

    const visiblePages = this.getVisiblePages(page, this.props.pages);

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages),
    });

    this.handleBreadcrumbs(page);

    this.props.onPageChange(page - 1);
  };

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const activePage = this.props.page + 1;

    return (
      <div className='marketplace-datatable_navbar'>
        <div className='marketplace-datatable_navbar-buttons'>
          {this.props.showCSVButton && (
            <TableButtonCSV
              title={this.props.title}
              headers={this.props.headers}
              columns={this.props.columns}
              data={this.props.data}
              selectedData={this.props.selectedData}
              dataForButtonActions={this.props.dataForButtonActions}
            />
          )}
          {this.props.showPrintButton && (
            <TableButtonPrint
              title={this.props.title}
              headers={this.props.headers}
              columns={this.props.columns}
              data={this.props.data}
              selectedData={this.props.selectedData}
              dataForButtonActions={this.props.dataForButtonActions}
            />
          )}
          {this.props.showWatchlistButton && (
            <TableButtonWatchlist
              title={this.props.title}
              headers={this.props.headers}
              columns={this.props.columns}
              data={this.props.data}
              selectedData={this.props.selectedData}
              dataForButtonActions={this.props.dataForButtonActions}
            />
          )}
        </div>

        <div className='marketplace-datatable_navbar-pagination'>
          <div className='marketplace-datatable_navbar-pagination__options'>
            {this.props.showPageSizeOptions && (
              <div className="select-wrap -pageSizeOptions">
                <span style={{ marginRight: '10px' }}>Show</span>
                <select
                  style={{ outline: 'none' }}
                  aria-label={this.props.rowsSelectorText}
                  value={this.props.pageSize}
                  onChange={e =>
                    this.props.onPageSizeChange(Number(e.target.value))
                  }
                >
                  {this.props.pageSizeOptions.map((option, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <option key={i} value={option}>
                      {`${option} ${this.props.rowsText}`}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>

          <div className='marketplace-datatable_navbar-pagination__pages'>
            <PageButtonComponent
              className={'datatable_navbar-pagination__button'}
              onClick={() => {
                if (activePage === 1) return;
                this.changePage(activePage - 1);
              }}
              disabled={activePage === 1}
            >
              {this.props.previousText}
            </PageButtonComponent>

            <div>
              {this.state.visiblePages.map((page, index, array) => {
                return (
                  <PageButtonComponent
                    key={page}
                    style={
                      activePage === page
                        ? styles.activePageStyle
                        : styles.inactivePageStyle
                    }
                    onClick={this.changePage.bind(null, page)}
                  >
                    {array[index - 1] + 2 < page ? `...${page}` : page}
                  </PageButtonComponent>
                );
              })}
            </div>

            <PageButtonComponent
              className={'datatable_navbar-pagination__button'}
              onClick={() => {
                if (activePage === this.props.pages) return;
                this.changePage(activePage + 1);
              }}
              disabled={activePage === this.props.pages}
            >
              {this.props.nextText}
            </PageButtonComponent>
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  buttons: {
    display: 'flex',
    width: '40%',
  },
  paginationWrapper: {
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  pageOptions: {
    display: 'flex',
    padding: 2,
  },
  pages: {
    display: 'flex',
    padding: '2px 10px',
    minWidth: '25%',
  },
  inactivePageStyle: {
    fontSize: '15px',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
  },
  activePageStyle: {
    fontSize: '15px',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    color: '#2D6FAE',
    fontWeight: 'bold',
  },
};

TablePaginationAndButtons.defaultProps = {
  showCSVButton: true,
  showPrintButton: false,
};

TablePaginationAndButtons.propTypes = {
  pages: PropTypes.number,
  page: PropTypes.number,
  PageButtonComponent: PropTypes.any,
  onPageChange: PropTypes.func,
  previousText: PropTypes.string,
  nextText: PropTypes.string,
  showCSVButton: PropTypes.bool,
  showPrintButton: PropTypes.bool,
};

export default TablePaginationAndButtons;
