import { FETCH_API } from "../../middleware";

/* GET - Notifications List */
export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAILURE = 'NOTIFICATIONS_FAILURE';

const fetchNotifications = mpId => {
  return {
    [FETCH_API]: {
      types: [
        NOTIFICATIONS_REQUEST,
        NOTIFICATIONS_SUCCESS,
        NOTIFICATIONS_FAILURE,
      ],
      endpoint: `notification-list?mpId=${mpId}&apiKey=&deviceTypeId=6`,
      method: 'GET',
    },
  };
};
export const loadNotifications = mpId => {
  return dispatch => {
    return dispatch(fetchNotifications(mpId));
  };
};

/* POST (Action) - Mark Notification as: 'Read' | 'Unread' */
export const NOTIFICATIONS_READ_REQUEST = 'NOTIFICATIONS_READ_REQUEST';
export const NOTIFICATIONS_READ_SUCCESS = 'NOTIFICATIONS_READ_SUCCESS';
export const NOTIFICATIONS_READ_FAILURE = 'NOTIFICATIONS_READ_FAILURE';

const fetchReadNotification = (mpId, notificationId, userId, markAsRead) => {
  return {
    [FETCH_API]: {
      types: [
        NOTIFICATIONS_READ_REQUEST,
        NOTIFICATIONS_READ_SUCCESS,
        NOTIFICATIONS_READ_FAILURE,
      ],
      endpoint: `notification-read` + 
        `?mpId=${mpId}`+ 
        `&userId=${userId}` +
        `&deviceTypeId=6` +
        `&apiKey=`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify([{
        notificationId, 
        markAsRead
      }]),
    },
  };
};

/* (Dispatch) Notification - Mark as 'Read' */
export const loadReadNotification = (mpId, notificationId, userId) => {
  return dispatch => {
    return dispatch(fetchReadNotification(mpId, notificationId, userId, true));
  };
};

/* (Dispatch) Notification - Mark as 'Unread' */
export const loadUnReadNotification = (mpId, notificationId, userId) => {
  return dispatch => {
    return dispatch(fetchReadNotification(mpId, notificationId, userId, false));
  };
};

/* POST (Action) - Mark ALL Notification as: 'Read' | 'Unread' */
export const NOTIFICATIONS_ALL_READ_REQUEST = 'NOTIFICATIONS_ALL_READ_REQUEST';
export const NOTIFICATIONS_ALL_READ_SUCCESS = 'NOTIFICATIONS_ALL_READ_SUCCESS';
export const NOTIFICATIONS_ALL_READ_FAILURE = 'NOTIFICATIONS_ALL_READ_FAILURE';
/**
 * 
 * @param {string|number} mpId 
 * @param {number[]} items 
 * @param {string|number} userId 
 * @returns 
 */
const fetchReadAllNotifications = (mpId, itemIds=[], userId) => {
  const items = itemIds.map(itemId => {
    return {notificationId: itemId, markAsRead: true};
  })
  return {
    [FETCH_API]: {
      types: [
        NOTIFICATIONS_ALL_READ_REQUEST,
        NOTIFICATIONS_ALL_READ_SUCCESS,
        NOTIFICATIONS_ALL_READ_FAILURE,
      ],
      endpoint: `notification-read` + 
        `?mpId=${mpId}`+ 
        `&userId=${userId}` +
        `&deviceTypeId=6` +
        `&apiKey=`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(items),
    },
  };
};
/* (Dispatch) Notification - Mark ALL as 'Read' */
export const loadReadAllNotifications = (mpId, itemIds, userId) => {
    return dispatch => {
        return dispatch(fetchReadAllNotifications(mpId, itemIds, userId));
    };
};