import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Icon from '../ui/icons';
import Button from '../ui/buttons';
import Loader from '../ui/loader';
import {
  CYAN_01,
  BLACK_02,
  GREY_05,
  WHITE_01,
  GREY_04,
  GREY_07,
} from '../../constants/colors';

let styles = {};

class VinSearchModal extends Component {

  renderHeader() {
    return (
      <div className="flex items-center justify-between mb-10">
        <div
          className="flex justify-center items-center border rounded-full p-2"
          style={{ visibility: 'hidden' }}
          onClick={this.props.onBack}
        >
          <Icon name="LeftArrow" size={14} color={CYAN_01} />
        </div>

        <div className="pl-4 fs-20 font-semibold" style={{ color: BLACK_02 }} />

        <div
          className="flex justify-center items-center border rounded-full"
          onClick={this.props.onClose}
        >
          <Icon name="Cross" size={32} color={CYAN_01} />
        </div>
      </div>
    );
  }

  renderInput() {
    return (
      <div>
        <div style={styles.inputContainer}>
          <input
            onFocus={e =>
              e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
              )
            }
            style={styles.input}
            autoFocus
            type="text"
            value={this.props.vinInput}
            onChange={this.props.handleVinInput}
            placeholder="Enter VIN"
          />

          <div
            style={{ visibility: this.props.vinInput ? 'visible' : 'hidden' }}
            onClick={this.props.handleVinClear}
          >
            <Icon name="Cross" size={24} color={CYAN_01} />
          </div>
        </div>
        {this.renderValidationMessage()}
      </div>
    );
  }

  renderButtons() {
    const {isVinInputFromScan} = this.props;
    const buttonText = isVinInputFromScan
      ? "Continue"
      : "Search";
    const buttonIcon = isVinInputFromScan
      ? "ChevronRight"
      : "Search"
    return (
      <div>
        <div className="modal-mt">
          <Button
            btnText={buttonText}
            height="43px"
            borderRadius="10px"
            fontSize="16px"
            iconName={buttonIcon}
            marginRight="12px"
            iconSize={18}
            iconColor={WHITE_01}
            outlinedIconColor={WHITE_01}
            outlined={this.props.vinInput.length < 4}
            outlinedTextColor={WHITE_01}
            outlinedBgColor={GREY_04}
            outlinedBorderColor={GREY_04}
            onClickHandler={this.props.onNext}
            // disabled={this.props.vinInput.length < 4}
            // loading={this.props.isLoading}
          />
        </div>
       {this.props.isScanEnabled ? 
        (<div className="modal-mt">
            <Button
              btnText="Scan"
              height="43px"
              borderRadius="10px"
              fontSize="16px"
              iconName="Barcode"
              marginRight="12px"
              iconSize={22}
              iconColor={WHITE_01}
              outlinedIconColor={WHITE_01}
              outlinedTextColor={WHITE_01}
              outlinedBgColor={GREY_07}
              outlinedBorderColor={GREY_07}
              onClickHandler={this.props.onScanNext}
            />
        </div>) : null}
        <div className="modal-mt">
          <Button
            btnText="Cancel"
            outlined={true}
            outlinedTextColor={BLACK_02}
            outlinedBgColor={GREY_05}
            outlinedBorderColor={GREY_05}
            fontSize="16px"
            borderRadius="10px"
            onClickHandler={this.props.onClose}
            // iconName="Cross"
            // iconColor={WHITE_01}
            // outlinedIconColor={BLACK_02}
            // iconSize={26}
            // marginRight="12px"
          />
        </div>
      </div>
    );
  }

  renderValidationMessage() {
    if (this.props.showValidationError) {
      return (
        <div style={styles.validationMessage}>
          Please enter a VIN Search Term of at least 4 characters
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          style={styles.modalStyles}
          ariaHideApp={false}
          shouldCloseOnEsc
        >
          <div className="landing-page-modal-width" style={{ minHeight: 260 }}>
            {this.props.isLoading ? (
              <Loader />
            ) : (
              <div>
                {this.renderHeader()}
                <div className="search-main-container">
                  <div>
                    {this.renderInput()}
                    {this.renderButtons()}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

styles = {
  modalStyles: {
    content: {
      display: 'block',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      zIndex: 99,
      position: 'absolute',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, .6)',
      zIndex: 99,
    },
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    border: '2px solid #e6e6e6',
    borderRadius: 10,
    overflow: 'hidden',
  },
  input: {
    outline: 'none',
    border: 'none',
    width: '100%',
    textAlign: 'center',
    fontSize: 16,
  },
  validationMessage: {
    textAlign: 'center',
    color: '#EF4444',
    marginTop: 8,
    fontSize: 14,
  },
};

VinSearchModal.propTypes = {
  handleVinClear: PropTypes.func.isRequired,
  handleVinInput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isScanEnabled: PropTypes.bool.isRequired,
  isVinInputFromScan: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  showValidationError: PropTypes.bool.isRequired,
  vinInput: PropTypes.string,
};

const mapStateToProps = state => {
  const { loadingBar } = state;
  const { accountList, accountListLoading, eventList, item, vin } = state.core;
  return { loadingBar, accountList, accountListLoading, eventList, item, vin };
};

export default connect(mapStateToProps, {})(VinSearchModal);
