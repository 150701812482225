import React from "react";
import { Button } from "semantic-ui-react";

import { getTemplate } from "../../../utils/helpers";
import { useSelector } from "react-redux";

/** Requires `webConfig.stratosConfig` and running `StratosLoader` script, which will attach to this element.
 * 
 * @param {{styles: React.CSSProperties}} props 
 * @returns {React.JSX.Element}
 */
const VehicleValuesButton = ({styles={}}) => {
    const template = useSelector(state => state.entities.template);

    return (
        <Button
            className='darwinVehicleButton'
            style={{
                ...defaultStyles.infoButton,
                ...styles,
                backgroundColor: getTemplate(
                    template,
                    'detailsMMRButton.backgroundColor'
                )
            }}
        >
            Vehicle Values
        </Button>
    )
}

const defaultStyles = {
    infoButton: {
        color: 'white',
        marginTop: 5,
        marginBottom: 5,
        width: 'auto',
    }
}

export {VehicleValuesButton};