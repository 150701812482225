import { combineReducers } from 'redux';
import { Reducer, createAction, createReducer } from '@reduxjs/toolkit';
import { routerReducer } from 'react-router-redux';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { assign, values } from 'lodash';
import * as ActionTypes from '../actions';
import { makeTemplate } from '../utils/helpers';
import { coreReducer as core } from './core';
import { mpConfigReducer as mpConfig } from './mpConfig';

const initialState = {
  accountSummary: {},
  acertusContactSubmission: {success: false, submitted: false, response: {}},
  addPrivateLotItemsSuccess: {},
  addWatchList: {},
  allActivity: {},
  allActivityES: {},
  allRunLists: {},
  arbitration: {
    buyerAddComment: {},
    buyerCancelArbitration: {},
    buyerComments: [], 
    buyerNewArbitration: {},
    sellerAddComment: {},
    sellerComments: [], 
  },
  ariTermsAndConditions: {},
  attributes: {},
  auctionArbitration: {},
  authorizedEvents: {},
  authorizedSellerEvents: {},
  biddingOn: {},
  bidHistory: {},
  bidMessageHistoryRealTime: [],
  bidMessagesRealTime: {},
  buyerBids: {},
  buyerIfs: {},
  buyerInvites: { invitedList: [], buttonText: 'Invite' },
  buyerList: {},
  buyerOffers: {},
  buyerPurchased: {},
  buyerSearchResults: {},
  buyerTitles: {},
  buyerTransportation: {},
  buyNow: {},
  buyNowCount: {},
  buyNowMessage: {},
  carfaxCode: '',
  carfaxLoginCheck: {},
  categories: {},
  customerPlatformLink: null,
  customerTermsLink: null,
  onlineConnected: undefined,
  createPrivateLotError: {},
  createPrivateLotSuccess: {},
  dealers: {},
  event: {},
  eventCommand: {},
  eventInfo: {},
  events: {},
  eventStatus: {},
  gatePass: {},
  geocode: {},
  hyperLot: {},
  item: {},
  itemCount: {},
  itemsWithBidOrOffer: [],
  listingStatusIds: [],
  login: {},
  marketplaceCount: {},
  marketplaceFeatures: {},
  marketplaceRecentlyAdded: {},
  marketplaces: {},
  maxCardHeight: 0,
  mmr: {},
  mostRecent: {},
  noSalesES: {},
  notificationRead: {},
  notifications: {},
  offer: {},
  privateLot: {},
  privateLotItems: {},
  realTimeConnected: undefined,
  recalls: {},
  recentlyAdded: {},
  recentlyViewed: {},
  removeWatchList: {},
  reportSummary: {},
  retailCount: {},
  retailItems: {},
  retailView: false,
  runList: {},
  runListES: {},
  runningToday: {},
  runningTodayChild: {},
  searchMarketplace: {},
  sellerBids: {},
  sellerIfAccept: {},
  sellerIfReject: {},
  sellerIfs: {},
  sellerInventory: {},
  sellerOffers: {},
  sellerCarOffers: {},
  sellerSold: {},
  sellerTitles: {},
  sellingNow: {},
  sellingNowCount: {},
  sellNow: {},
  serverTime: {},
  shippingQuote: {},
  simulcastStatus: {},
  spinCars: {},
  tableRef: null,
  template: makeTemplate({}, true),
  tradeCount: {},
  tradeItems: {},
  transportationTypes: {},
  unauthorizedEventIds: [],
  userAlerts: {},
  userItems: {},
  userProfile: {},
  userSelectedDistance: '',
  userSelectedLocation: {},
  watchlist: {},
  watchlistES: {},
  watchlistUserItems: {},
  wishlistItems: {},
  watchlistObject: {},
};

const entities = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REFRESH_APIKEY_SUCCESS:
      return { ...state, login: action.response };

    case ActionTypes.LOGIN_SUCCESS:
      return assign({}, state, {
        login: action.response,
      });
    case ActionTypes.RESET_LOGIN:
      return assign({}, state, {
        login: {},
      });

    case ActionTypes.MARKETPLACE_FEATURES_SUCCESS:
      return assign({}, state, {
        marketplaceFeatures: action.response,
      });

    case ActionTypes.EVENTS_SUCCESS:
      return assign({}, state, {
        events: action.response,
      });
    case ActionTypes.RESET_EVENTS:
      return assign({}, state, {
        events: {},
      });
    case ActionTypes.MARKETPLACES_SUCCESS:
      return assign({}, state, {
        marketplaces: action.response,
      });
    case ActionTypes.RESET_MARKETPLACES:
      return assign({}, state, {
        marketplaces: {},
      });
    case ActionTypes.RUNLIST_SUCCESS:
      return { ...state, runList: action.response };

    case ActionTypes.RUNLIST_ES_SUCCESS:
      return { ...state, runListES: action.response };

    case ActionTypes.RESET_RUNLIST_ES:
      return { ...state, runListES: {} };

    case ActionTypes.ALL_RUNLISTS_SUCCESS:
      return assign({}, state, {
        allRunlists: action.response,
      });
    case ActionTypes.ITEM_SUCCESS:
      return assign({}, state, {
        item: action.response,
      });
    case ActionTypes.PARENT_ITEM_SUCCESS:
      return assign({}, state, {
        parentItem: action.response,
      });
    case ActionTypes.RESET_PARENT_ITEM:
      return assign({}, state, {
        parentItem: {},
      });
    case ActionTypes.RESET_ITEM:
      return assign({}, state, {
        item: {},
      });
    case ActionTypes.BID_HISTORY_SUCCESS:
      return { ...state, bidHistory: action.response };

    case ActionTypes.RESET_BID_HISTORY:
      return { ...state, bidHistory: {} };

    case ActionTypes.EVENT_INFO_SUCCESS:
      return { ...state, eventInfo: action.response };

    case ActionTypes.RESET_EVENT_INFO:
      return { ...state, eventInfo: {} };

    case ActionTypes.USER_PROFILE_SUCCESS: {
      return { ...state, userProfile: action.response };
    }

    case ActionTypes.UPDATE_USER_PROFILE_SUCCESS:
      return { ...state, userProfile: action.response };

    case ActionTypes.RESET_USER_PROFILE:
      return { ...state, userProfile: {} };

    // arbitration - buyer
    case ActionTypes.BUYER_ARBITRATION_ADD_COMMENT_SUCCESS:
      return { 
        ...state, 
        arbitration: {...state.arbitration, buyerAddComment: action.response}
      }
    
    case ActionTypes.BUYER_ARBITRATION_COMMENTS_SUCCESS:
      return { 
        ...state, 
        arbitration: {...state.arbitration, buyerComments: action?.response?.comments}
      }
  
    case ActionTypes.BUYER_ARBITRATION_NEW_SUCCESS:
      return { 
        ...state, 
        arbitration: {...state.arbitration, buyerNewArbitration: action.response}
      }
    
    case ActionTypes.BUYER_ARBITRATION_CANCEL_SUCCESS:
      return {
        ...state,
        arbitration: {...state.arbitration, buyerCancelArbitration: action.response}
      }

    // arbitration - seller
    case ActionTypes.SELLER_ARBITRATION_ADD_COMMENT_SUCCESS:
      return {
        ...state,
        arbitration: {...state.arbitration, sellerAddComment: action?.response?.comments}
      }

    case ActionTypes.SELLER_ARBITRATION_COMMENTS_SUCCESS:
      return { 
        ...state, 
        arbitration: {...state.arbitration, sellerComments: action?.response?.comments || []}
      }
    // arbitration - reset -- seller | buyer
    case ActionTypes.RESET_SELLER_ARBITRATION:
      return {
        ...state,
        arbitration: {...state.arbitration, sellerComments: [], sellerAddComment: {}}
      }
    
    case ActionTypes.RESET_BUYER_ARBITRATION:
      return {
        ...state,
        arbitration: {
          ...state.arbitration, 
          buyerComments: [], 
          buyerAddComment: {}, 
          buyerCancelArbitration: {},
          buyerNewArbitration: {},
        }
      }
    
    // my account buyer and seller
    case ActionTypes.ACCOUNT_SUMMARY_SUCCESS:
      return { ...state, accountSummary: action.response };

    case ActionTypes.RESET_ACCOUNT_SUMMARY:
      return { ...state, accountSummary: {} };

    case ActionTypes.GATE_PASS_SUCCESS:
      return { ...state, gatePass: action.response };

    case ActionTypes.RESET_GATE_PASS:
      return { ...state, gatePass: {} };

    case ActionTypes.AUCTION_ARBITRATION_SUCCESS:
      return { ...state, auctionArbitration: action.response };

    case ActionTypes.RESET_AUCTION_ARBITRATION:
      return { ...state, auctionArbitration: {} };

    // my account buyer
    case ActionTypes.BUYER_BIDS_SUCCESS:
      return { ...state, buyerBids: action.response };

    case ActionTypes.RESET_BUYER_BIDS:
      return { ...state, buyerBids: {} };

    case ActionTypes.BUYER_OFFERS_SUCCESS:
      return { ...state, buyerOffers: action.response };

    case ActionTypes.RESET_BUYER_OFFERS:
      return { ...state, buyerOffers: {} };

    case ActionTypes.BUYER_IFS_SUCCESS:
      return { ...state, buyerIfs: action.response };

    case ActionTypes.RESET_BUYER_IFS:
      return { ...state, buyerIfs: {} };

    case ActionTypes.BUYER_PURCHASED_SUCCESS:
      return { ...state, buyerPurchased: action.response };

    case ActionTypes.RESET_BUYER_PURCHASED:
      return { ...state, buyerPurchased: {} };

    case ActionTypes.BUYER_TITLES_SUCCESS:
      return { ...state, buyerTitles: action.response };

    case ActionTypes.RESET_BUYER_TITLES:
      return { ...state, buyerTitles: {} };

    case ActionTypes.BUYER_TRANSPORTATION_SUCCESS:
      return { ...state, buyerTransportation: action.response };

    case ActionTypes.RESET_BUYER_TRANSPORTATION:
      return { ...state, buyerTransportation: {} };

    // my account seller
    case ActionTypes.SELLER_INVENTORY_SUCCESS:
      return { ...state, sellerInventory: action.response };

    case ActionTypes.RESET_SELLER_INVENTORY:
      return { ...state, sellerInventory: {} };

    case ActionTypes.SELLER_BIDS_SUCCESS:
      return { ...state, sellerBids: action.response };

    case ActionTypes.RESET_SELLER_BIDS:
      return { ...state, sellerBids: {} };

    case ActionTypes.SELLER_OFFERS_SUCCESS:
      return { ...state, sellerOffers: action.response };

    case ActionTypes.RESET_SELLER_OFFERS:
      return { ...state, sellerOffers: {} };

    case ActionTypes.SELLER_IF_ACCEPT_SUCCESS:
      return { ...state, sellerIfAccept: action.response };
    
    case ActionTypes.SELLER_IF_REJECT_SUCCESS:
      return { ...state, sellerIfReject: action.response };

    case ActionTypes.SELLER_IFS_SUCCESS:
      return { ...state, sellerIfs: action.response };

    case ActionTypes.RESET_SELLER_IFS:
      return { ...state, sellerIfs: {} };

    case ActionTypes.DEALERS_SUCCESS:
      return assign({}, state, {
        dealers: action.response,
      });

    case ActionTypes.SELLER_EXT_OFFERS_SUCCESS:
      return assign({}, state, {
        sellerCarOffers: action.response,
      });

    case ActionTypes.SELLER_SOLD_SUCCESS:
      return { ...state, sellerSold: action.response };

    case ActionTypes.RESET_SELLER_SOLD:
      return { ...state, sellerSold: {} };

    case ActionTypes.SELLER_TITLES_SUCCESS:
      return { ...state, sellerTitles: action.response };

    case ActionTypes.RESET_SELLER_TITLES:
      return { ...state, sellerTitles: {} };

    case ActionTypes.WATCHLIST_SUCCESS:
      return { ...state, watchlist: action.response };

    case ActionTypes.WATCHLIST_USER_ITEMS_SUCCESS:
      return { ...state, watchlistUserItems: action.response };

    case ActionTypes.WATCHLIST_ES_SUCCESS:
      return { ...state, watchlistES: action.response };

    case ActionTypes.WATCHLIST_OBJECT_SUCCESS: {
      // turn the list into an object so we can do direct lookups
      const { wsStatus, wsMessage, items } = action.response;
      const watchlistObject = (items || []).reduce(
        (obj, item) => ({ ...obj, [item.itemId]: item.dateCreated }),
        { wsStatus, wsMessage }
      );
      return { ...state, watchlistObject };
    }

    case ActionTypes.SELLING_NOW_SUCCESS:
      return assign({}, state, {
        sellingNow: action.response,
      });
    case ActionTypes.BUY_NOW_SUCCESS:
      return assign({}, state, {
        buyNow: action.response,
      });
    case ActionTypes.REPORT_SUMMARY_SUCCESS:
      return assign({}, state, {
        reportSummary: action.response,
      });
    case ActionTypes.ADD_WATCH_LIST_SUCCESS:
      return assign({}, state, {
        addWatchList: action.response,
      });
    case ActionTypes.REMOVE_WATCH_LIST_SUCCESS:
      return assign({}, state, {
        removeWatchList: action.response,
      });
    case ActionTypes.RESET_ADD_WATCH_LIST:
      return assign({}, state, {
        addWatchList: {},
      });
    case ActionTypes.RESET_REMOVE_WATCH_LIST:
      return assign({}, state, {
        removeWatchList: {},
      });
    case ActionTypes.EVENT_COMMAND_SUCCESS:
      return assign({}, state, {
        eventCommand: action.response,
      });
    case ActionTypes.RESET_EVENT_COMMAND:
      return assign({}, state, {
        eventCommand: {},
      });
    case ActionTypes.EVENT_STATUS_SUCCESS:
      return assign({}, state, {
        eventStatus: action.response,
      });
    case ActionTypes.SIMULCAST_STATUS_SUCCESS:
      return assign({}, state, {
        simulcastStatus: action.response,
      });
    case ActionTypes.RECALLS_SUCCESS:
      return assign({}, state, {
        recalls: action.response,
      });
    case ActionTypes.RESET_RECALLS:
      return assign({}, state, {
        recalls: {},
      });

    case ActionTypes.RECENTLY_ADDED_SUCCESS:
      return { ...state, recentlyAdded: action.response };

    case ActionTypes.MOST_RECENT_SUCCESS:
      return { ...state, mostRecent: action.response };

    case ActionTypes.MARKETPLACE_RECENTLY_ADDED_SUCCESS:
      return assign({}, state, {
        marketplaceRecentlyAdded: action.response,
      });
    case ActionTypes.RUNNING_TODAY_SUCCESS:
      return assign({}, state, {
        runningToday: action.response,
      });
    case ActionTypes.RUNNING_TODAY_CHILD_SUCCESS:
      return assign({}, state, {
        runningTodayChild: action.response,
      });
    case ActionTypes.RETAIL_ITEMS_SUCCESS:
      return assign({}, state, {
        retailItems: action.response,
      });
    case ActionTypes.TRADE_ITEMS_SUCCESS:
      return assign({}, state, {
        tradeItems: action.response,
      });
    case ActionTypes.ITEM_COUNT_SUCCESS:
      return assign({}, state, {
        itemCount: action.response,
      });
    case ActionTypes.BUY_NOW_COUNT_SUCCESS:
      return assign({}, state, {
        buyNowCount: action.response,
      });
    case ActionTypes.SELLING_NOW_COUNT_SUCCESS:
      return assign({}, state, {
        sellingNowCount: action.response,
      });
    case ActionTypes.MARKETPLACE_COUNT_SUCCESS:
      return assign({}, state, {
        marketplaceCount: action.response,
      });
    case ActionTypes.RETAIL_COUNT_SUCCESS:
      return assign({}, state, {
        retailCount: action.response,
      });
    case ActionTypes.TRADE_COUNT_SUCCESS:
      return assign({}, state, {
        tradeCount: action.response,
      });

    // case ActionTypes.SEARCH_MARKETPLACE_SUCCESS:
    //   return assign({}, state, {
    //     searchMarketplace: action.response,
    //   });
    case ActionTypes.WISHLIST_ITEMS_SUCCESS:
      return assign({}, state, {
        wishlistItems: action.response,
      });
    case ActionTypes.BIDDING_ON_SUCCESS:
      return assign({}, state, {
        biddingOn: action.response,
      });
    case ActionTypes.RECENTLY_VIEWED_SUCCESS:
      return assign({}, state, {
        recentlyViewed: action.response,
      });
    case ActionTypes.ES_HAS_BID_OR_OFFER_SUCCESS:
      return assign({}, state, {
        itemsWithBidOrOffer: action.response,
      })
    case ActionTypes.PRIVATE_LOT_SUCCESS:
      return assign({}, state, {
        privateLot: action.response,
      });
    case ActionTypes.HYPER_LOT_SUCCESS:
      return assign({}, state, {
        hyperLot: action.response,
      });
    case ActionTypes.EVENTS_SELLER_SUCCESS:
      return assign({}, state, {
        event: action.response,
      });
    case ActionTypes.USER_ALERTS_SUCCESS:
      return assign({}, state, {
        userAlerts: action.response,
      });
    case ActionTypes.BUYER_LIST_SUCCESS:
      values(action.response.items).forEach(
        buyer => (buyer.buttonText = 'Invite')
      );
      return assign({}, state, {
        buyerList: action.response,
      });
    case ActionTypes.BUYER_INVITE_LIST_SUCCESS:
      return assign({}, state, {
        buyerInvites: state.buyerInvites,
      });
    case ActionTypes.SELL_NOW_SUCCESS:
      return assign({}, state, {
        sellNow: state.sellNow,
      });
    case ActionTypes.TRANSPORTATION_TYPES_SUCCESS:
      return assign({}, state, {
        transportationTypes: action.response,
      });

    case ActionTypes.BUYER_INVITE_REMOVE_SUCCESS:
      let buyerRemoveInvites = Object.assign({}, state.buyerInvites);

      let buyerRemoveResults = Object.assign(
        {},
        state.buyerSearchResults.items
          ? state.buyerSearchResults
          : state.buyerList
      );

      buyerRemoveResults.items = buyerRemoveResults.items.map(
        (buyer, index) => {
          if (index === action.removeProps.index) {
            buyer.buttonText = 'Invite';
          }
          return buyer;
        }
      );

      return assign({}, state, {
        buyerInvites: buyerRemoveInvites,
        buyerSearchResults: buyerRemoveResults,
      });

    case ActionTypes.BUYER_INVITE_ADD_SUCCESS:
      let buyerAddInvites = Object.assign({}, state.buyerInvites);
      let buyerAddResults = Object.assign(
        {},
        state.buyerSearchResults.items
          ? state.buyerSearchResults
          : state.buyerList
      );

      buyerAddInvites.invitedList = [];
      buyerAddResults.items = buyerAddResults.items.map((buyer, index) => {
        if (action.addProps.index === index) {
          buyer.buttonText = 'Invited';
          buyerAddInvites.invitedList.push(buyer);
        } else if (buyer.buttonText === 'Invited') {
          buyerAddInvites.invitedList.push(buyer);
        }
        return buyer;
      });

      return assign({}, state, {
        buyerInvites: buyerAddInvites,
        buyerSearchResults: buyerAddResults,
      });
    case ActionTypes.CLEAR_BUYER_INVITES:
      let buyerClearInvites = Object.assign({}, state.buyerInvites);
      buyerClearInvites.invitedList = [];

      values(state.buyerSearchResults.items).forEach(
        buyer => (buyer.buttonText = 'Invite')
      );

      return assign({}, state, {
        buyerInvites: buyerClearInvites,
        buyerSearchResults: state.buyerSearchResults,
      });

    case ActionTypes.BUYER_SEARCH_SUCCESS:
      values(action.response.items).forEach(
        buyer => (buyer.buttonText = 'Invite')
      );
      return assign({}, state, {
        buyerSearchResults: action.response,
      });
    case ActionTypes.SAVE_PRIVATE_LOT_SUCCESS:
      return assign({}, state, {
        createPrivateLotSuccess: action.successResponse,
      });
    case ActionTypes.SAVE_PRIVATE_LOT_FAILURE:
      return assign({}, state, {
        createPrivateLotError: action.errorResponse,
      });
    
    case ActionTypes.NOTIFICATIONS_READ_SUCCESS:
    case ActionTypes.NOTIFICATIONS_ALL_READ_SUCCESS:
      return assign({}, state, {
        notificationRead: action.response,
      })
    case ActionTypes.NOTIFICATIONS_SUCCESS:
      return assign({}, state, {
        notifications: action.response,
      });
    case ActionTypes.ALL_ACTIVITY_SUCCESS:
      return assign({}, state, {
        allActivity: action.response,
      });
    case ActionTypes.ADD_PRIVATE_LOT_ITEMS_SUCCESS:
      return assign({}, state, {
        addPrivateLotItemsSuccess: action.response,
      });
    case ActionTypes.ALL_ACTIVITY_ES_SUCCESS:
      return assign({}, state, {
        allActivityES: action.response,
      });
    case ActionTypes.SERVER_TIME_SUCCESS:
      return assign({}, state, {
        serverTime: action.response,
      });
    case ActionTypes.RETAIL_VIEW_ON:
      return assign({}, state, {
        retailView: true,
      });
    case ActionTypes.RETAIL_VIEW_OFF:
      return assign({}, state, {
        retailView: false,
      });

    case ActionTypes.CARFAX_LOGIN_CHECK_SUCCESS:
      return { ...state, carfaxLoginCheck: action.response };

    case ActionTypes.CARFAX_CODE_SUCCESS:
      return { ...state, carfaxCode: action.response };

    case ActionTypes.RESET_CARFAX_CODE:
      return { ...state, carfaxCode: '' };

    case ActionTypes.MMR_SUCCESS:
      return { ...state, mmr: action.response };

    case ActionTypes.RESET_MMR:
      return { ...state, mmr: {} };

    case ActionTypes.ITEM_OFFER_SUCCESS:
      return { ...state, offer: action.response };

    case ActionTypes.ITEM_OFFER_RESET:
      return { ...state, offer: {} };

    case ActionTypes.ITEM_UPDATE_OFFER_SUCCESS:
      return { ...state, offer: action.response };

    case ActionTypes.NO_SALES_FROM_EVENTS_ES_SUCCESS:
      return { ...state, noSalesES: action.response };

    case ActionTypes.GEOCODE_SUCCESS:
      return { ...state, geocode: action.response };

    case ActionTypes.USER_ITEMS_SUCCESS:
      return { ...state, userItems: action.response };

    case ActionTypes.USER_SELECTED_LOCATION_SUCCESS:
      return { ...state, userSelectedLocation: action.response };

    case ActionTypes.USER_SELECTED_DISTANCE_SUCCESS:
      return { ...state, userSelectedDistance: action.response };

    case ActionTypes.SET_TABLE_REF_SUCCESS:
      return { ...state, tableRef: action.response };

    case ActionTypes.SET_TEMPLATE_SUCCESS:
      return { ...state, template: action.response };

    case ActionTypes.SPIN_CARS_SUCCESS: {
      // turn the list into an object so we can do direct lookups by vin
      const spinCars = (action.response.vehicles || []).reduce(
        (obj, item) => ({ ...obj, [(item.vin || '').toUpperCase()]: item.url }),
        {}
      );
      return { ...state, spinCars };
    }

    case ActionTypes.CATEGORIES_SUCCESS:
      return { ...state, categories: action.response };

    case ActionTypes.ATTRIBUTES_SUCCESS:
      return { ...state, attributes: action.response };

    case ActionTypes.FIND_MAX_CARD_HEIGHT: {
      return {
        ...state,
        maxCardHeight: Math.max(state.maxCardHeight || 0, action.height),
      };
    }

    case ActionTypes.SET_CUSTOMER_TERMS_LINK: {
      return { ...state, customerTermsLink: action.response };
    }

    case ActionTypes.SET_CUSTOMER_PLATFORM_LINK: 
      return { ...state, customerPlatformLink: action.response}

    case ActionTypes.RESET_MAX_CARD_HEIGHT:
      return { ...state, maxCardHeight: 0 };

    case ActionTypes.UPDATE_BID_MESSAGES_REAL_TIME_SUCCESS: {
      // const example_response = {
      //   listingId: 14589000,
      //   highBid: 1000,
      //   bidIncrement: 100,
      //   highBidderAccountId: 1077237,
      //   listingTypeId: 1,
      //   listingType: 'Proxy Auction',
      //   listingStatusId: 1,
      //   listingStatus: 'Auction in Progress',
      //   startingBid: 1,
      //   endDate: '7/26/2022 9:40:00 AM',
      //   epochStartTime: 1658824800000,
      //   epochEndTime: 1658828400000,
      //   isMPSellingLive: false,
      // };

      return {
        ...state,
        bidMessagesRealTime: {
          ...state.bidMessagesRealTime,
          [action.response.listingId]: action.response,
        },
      };
    }

    case ActionTypes.RESET_BID_MESSAGES_REAL_TIME: {
      return { ...state, bidMessagesRealTime: {} };
    }

    case ActionTypes.UPDATE_BID_MESSAGE_HISTORY_REAL_TIME_SUCCESS: {
      // const example_response = [
      //   {
      //     accountNumber: '26094ARI-MP',
      //     bidAmount: 1000,
      //     bidderCity: 'AUSTIN',
      //     bidderId: 1174717,
      //     bidderState: 'TX',
      //     bidType: 'Hard Bid',
      //     dateCreated: '2022-07-26T09:06:12.91',
      //     dealerId: 1077237,
      //     offerStatus: 'Active',
      //     offerStatusId: '1',
      //     userId: 1174717,
      //     offerId: 115539555,
      //     itemId: 14589000,
      //   },
      // ];

      return {
        ...state,
        bidMessageHistoryRealTime: [
          ...state.bidMessageHistoryRealTime,
          ...(action.response || []),
        ],
      };
    }

    case ActionTypes.RESET_BID_MESSAGE_HISTORY_REAL_TIME: {
      return { ...state, bidMessageHistoryRealTime: [] };
    }

    case ActionTypes.AUTHORIZED_EVENTS_SUCCESS: {
      return { ...state, authorizedEvents: action.response };
    }

    case ActionTypes.UNAUTHORIZED_EVENT_IDS: {
      return { ...state, unauthorizedEventIds: action.response };
    }
    
    case ActionTypes.AUTH_SELLER_EVENTS_SUCCESS: {
      /* example response
      {
        "apiKey": "",
        "expiresOn": "2024-08-19T16:05:13.1944348-04:00",
        "events": [
            {
                "eventId": 181717,
                "name": "TEST 1"
            },
            {
                "eventId": 181718,
                "name": "TEST 2"
            },
            {
                "eventId": 181719,
                "name": "TEST 3"
            }
        ],
        "wsStatus": "Success",
        "wsMessage": "Successfully completed the request."
    } */
      return { ...state, authorizedSellerEvents: action.response}
    }

    case ActionTypes.SET_LISTING_STATUS_IDS: {
      return { ...state, listingStatusIds: action.response };
    }

    case ActionTypes.ONLINE_CONNECTED: {
      return { ...state, onlineConnected: action.response };
    }

    case ActionTypes.REAL_TIME_CONNECTED: {
      return { ...state, realTimeConnected: action.response };
    }

    case ActionTypes.GET_SHIPPING_QUOTE_SUCCESS: {
      return { ...state, shippingQuote: action.response };
    }

    case ActionTypes.RESET_SHIPPING_QUOTE: {
      return { ...state, shippingQuote: {} };
    }

    case ActionTypes.ARI_TERMS_SUCCESS: {
      /* 
      const example_response = {
        "termId":           int,
        "applicationiName": str,
        "version":          str,   // "V12345678",
        "startDate":        str,   // YYYY-MM-DD + "T" + HH:MM:SS
        "termHtml":         str,   // HTML string;  Passed to T&C Modal
        "isEnabled":        bool,
        "createdBy":        str,
        "createdOn":        str,   // ^ date format
        "updatedBy":        str,
        "updatedOn":        str    // ^ date format
      }
      */
      return { ...state, ariTermsAndConditions: action.response };
    }

    case ActionTypes.SHIPPING_FORM_SUCCESS: {
      /* Successful POST response status === 200 */
      return { 
        ...state, 
        acertusContactSubmission: {
          success: true,
          submitted: true,
          response: action.response,
        } 
      }
    }

    case ActionTypes.SHIPPING_FORM_FAILURE: {
      return {
        ...state,
        acertusContactSubmission: {
          success: false,
          submitted: true,
          response: action?.response || null
        }
      }
    }

    case ActionTypes.RESET_SHIPPING_FORM: {
      return {
        ...state,
        acertusContactSubmission: {
          success: false,
          submitted: false,
          response: {}
        }
      }
    }

    default:
      return state;
  }
};

const errorMessage = (state = null, action) => {
  if (action.type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null;
  } else if (action.error) {
    return action.error;
  }

  return state;
};

const rootReducer = combineReducers({
  errorMessage,
  loadingBar: loadingBarReducer,
  toastr: toastrReducer,
  routing: routerReducer,
  entities,
  core,
  mpConfig,
});

export default rootReducer;
