import React from "react";

/**
 * @description An error message to display in place of table content
 * 
 * @param {{errorMessage?: string}} props
 */
const TableError = ({errorMessage}) => {

    const defaultErrorText = "Sorry! An error has occurred."

    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                paddingTop: 20,
                width: '100%',
            }}
        >
            <span>{errorMessage || defaultErrorText}</span>
        </div>
    )
}

export default TableError;