import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { find, get, isEmpty } from 'lodash';
import ImageGallery from 'react-image-gallery';
import Fullscreen from './imageGallery/Fullscreen';
import {
  commafyCurrency_NEW,
  getConfig,
  getParamFromUrl,
  isZero,
  makeAssetLink,
  makeReadableDate,
} from '../utils/helpers';
import { urlsMap } from '../utils/constants';
import '../assets/css/consolidated-cr.css';

const placeholder = '-';
const styles = {
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    width: '100%',
  },
  innerContainer: {
    width: '100%',
    maxWidth: 816,
    border: 'none',
    borderRadius: 4,
    padding: 15,
  },
  YMMT: {
    fontSize: 36,
    fontWeight: 500,
    color: 'black',
    paddingTop: 5,
    paddingBottom: 5,
  },
  bodyStyle: {
    fontSize: 24,
    fontWeight: 'normal',
    color: 'black',
    paddingTop: 10,
    paddingBottom: 5,
  },
  detailsAndImageContainer: {
    display: 'flex',
    justifyContent: 'space-apart',
    alignItems: 'flex-start',
  },
  detailsContainer: {
    width: '50%',
    padding: 5,
  },
  sectionContainer: {
    width: '100%',
  },
  sectionHeader: {
    backgroundColor: '#4a4a4a',
    color: '#fff',
    fontSize: 20,
    padding: 5,
    paddingLeft: 10,
  },
  mainImage: {
    width: '50%',
    objectFit: 'contain',
  },
  picturesContent: {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: 20,
    paddingTop: 5,
    paddingBottom: 5,
  },
  picturesImageContainer: {
    whiteSpace: 'wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 5,
    maxLines: 1,
    alignSelf: 'flex-start',
    textAlign: 'center',
    display: 'block',
  },
  picturesImage: {
    objectFit: 'cover',
    height: 110,
    width: 147,
    cursor: 'pointer',
  },
  pictureDescription: {
    fontSize: 12,
    minHeight: 10,
    padding: 2,
  },
  labelValueContainerVertical: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  labelValueContainerHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  label: {
    fontWeight: 900,
    fontSize: 14,
    color: 'black',
    marginRight: 5,
    minHeight: 20,
  },
  value: {
    fontSize: 18,
    minWidth: 50,
    minHeight: 20,
    color: '#787878',
  },
  horizontalLabel: {
    fontWeight: 900,
    fontSize: 14,
    color: 'black',
    marginRight: 5,
    minHeight: 20,
  },
  horizontalValue: {
    fontSize: 18,
    minWidth: 50,
    minHeight: 20,
    color: '#787878',
    paddingBottom: 2,
  },
  tireContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 100,
    padding: 5,
    color: '#787878',
  },
  tireRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 2,
    textAlign: 'center',
  },
  tireImage: {
    objectFit: 'contain',
    height: 70,
  },
  tireLabel: {
    fontWeight: 900,
    fontSize: 14,
    width: '20%',
    padding: 5,
    color: 'black',
  },
  tireValue: {
    width: '20%',
    padding: 5,
    color: '#787878',
  },
  divider: {
    backgroundColor: 'gray',
    height: 1,
    marginTop: 10,
    marginBottom: 10,
  },
  colHeaders: {
    minWidth: 60,
    color: 'black',
  },
  loadingError: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 50,
  },
};

class ConditionReportConsolidated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loaded: false,
      inVDP: isEmpty(props.itemData) ? false : true,
      error: '',
      showGallery: false,
      startIndex: 0,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemData.itemId !== this.props.itemData.itemId) {
      this.loadData();
    }
  }

  componentDidCatch(crRenderError) {
    console.error(crRenderError?.message || crRenderError);
    this.setState({
      data: null,
      loaded: true,
      error: 'An error occurred while loading the document.',
      startIndex: 0,
      showGallery: false,
    })
  }

  makeTotal = property => {
    return this.state.data.item.conditionItems.reduce(
      (total, conditionItem) =>
        (total += parseFloat(conditionItem[property], 0) || 0),
      0
    );
  };

  makeGrandTotalEstimate = () => {
    return [
      this.makeTotal('materialEstimate'),
      this.makeTotal('laborEstimate'),
      this.makeTotal('paintEstimate'),
      this.makeTotal('subletEstimate'),
    ].reduce((total, n = 0) => total + n, 0);
  };

  loadData() {
    const vehicleId =
      this.props.itemData.itemId || getParamFromUrl('vehicleid');
    const url =
      `${getConfig('apiRoot')}` +
      `ConditionReport/get-cr-item?vehicleid=${vehicleId}`;

    fetch(url)
      .then(res => res.json())
      .then(json => {
        if (json.message) throw new Error(json.message);
        this.setState({
          data: json || null,
          loaded: true,
          error: '',
        });
      })
      .catch(err => {
        this.setState({
          data: null,
          loaded: true,
          error: err.message || '',
          startIndex: 0,
          showGallery: false,
        });
      });
  }

  render() {
    const { loaded, data, inVDP, showGallery, startIndex } = this.state;

    if (!loaded || !data) {
      const text = !loaded ? 'Loading...' : this.state.error || 'Error...';
      return (
        <div style={styles.loadingError}>
          <h2>{text}</h2>
        </div>
      );
    }

    const { item } = data;

    const beautyShots = (item.medias || []).filter(
      item => item.photo && item.category !== 'Wheels'
    );
    const conditionShots = (item.conditionItems || []).filter(
      item => item.photo
    );
    const pictures = [...beautyShots, ...conditionShots]; // medias (no wheels) + condition photos

    const YMMT = [
      item.info.year || '',
      item.info.make || '',
      item.info.model || '',
      item.info.trim || '',
    ].join(' ');

    const bodyStyle = item.info.body || '';

    return (
      <div style={styles.outerContainer}>
        <div
          style={{
            ...styles.innerContainer,
            marginTop: inVDP ? 'auto' : 50,
            marginBottom: inVDP ? 'auto' : 50,
          }}
        >
          {/* HEADER */}
          <hr style={styles.divider} />
          <div className='cr-header_ymmt'>{YMMT}</div>
          <div style={styles.bodyStyle}>{bodyStyle}</div>
          <hr style={styles.divider} />

          {/* DETAILS + IMAGE */}
          <div 
            className='cr-details-container'
          >
            {/* DETAILS */}
            <div 
              className='cr-details-wrapper'
            >
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {[
                  ['VIN:', item.info.vin],
                  ['Ext Color:', item.info.exteriorColor],
                  [
                    'Odometer:',
                    `${item.odometer.reading} ${item.odometer.type}`,
                  ],
                  ['Interior Type:', item.info.interiorTrim],
                  ['Engine:', item.engine.description],
                  ['Drivetrain:', item.engine.driveTrain],
                  ['Transmission:', item.engine.transmission],
                  ['Int Color:', item.info.interiorColor],
                  ['Drivable:', item.details.driveable],
                  ['Structural:', item.details.frameDamage],
                ].map(([label, value], index) => (
                  <div
                    key={index}
                    style={{
                      ...styles.labelValueContainerVertical,
                      flex: index % 2 === 0 ? '1 1 60%' : '1 1 40%', // there are arranged in 2 columns, the left wider than the right
                      marginBottom: 20,
                    }}
                  >
                    <div style={styles.label}>{label}</div>
                    <div style={styles.value}>{value}</div>
                  </div>
                ))}
              </div>
            </div>

            {/* IMAGE */}
            <img
              style={styles.mainImage}
              id="main-image"
              src={get(item, 'medias[0].photo') || undefined}
              onError={e => {
                e.currentTarget.onerror = (nextEvt) => {
                  nextEvt.currentTarget.onerror = null;
                  nextEvt.target.src = urlsMap.get('IMAGE_ERROR_DARK');
                }
                e.target.src = makeAssetLink('placeholder.png');
              }}
              alt=""
            />
          </div>

          {/* OPTIONS */}
          <div style={styles.sectionContainer}>
            <div style={styles.sectionHeader}>Options</div>
            <div style={{ minHeight: 20, padding: 5 }}>
              {(item.equipments || [])
                .map(equipment => equipment.description)
                .join(', ')}
            </div>
          </div>

          {/* ANNOUNCEMENTS */}
          <div style={styles.sectionContainer}>
            <div style={styles.sectionHeader}>Announcements</div>
            <div style={{ minHeight: 20, padding: 5 }}>
              {item.auction.blockAnnouncementNotes}
            </div>
          </div>

          {/* NOTIFICATIONS */}
          <div style={styles.sectionContainer}>
            <div style={styles.sectionHeader}>Notifications</div>
            <div style={{ display: 'flex', minHeight: 20, padding: 5 }}>
              <div style={styles.labelValueContainerHorizontal}>
                <div style={styles.horizontalLabel}>CR Done:</div>
                <div style={styles.horizontalValue}>
                  {item.inspection.crDone === 'true' ? 'Yes ' : 'No '}
                </div>
              </div>

              <div style={styles.labelValueContainerHorizontal}>
                <div style={styles.horizontalLabel}>CR Inspector:</div>
                <div style={styles.horizontalValue}>
                  {item.inspection.inspector || 'N/A'}
                </div>
              </div>
            </div>
          </div>

          <div style={styles.sectionContainer}>
            <div style={styles.sectionHeader}>Tires</div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                minHeight: 20,
                padding: `5px 0`,
              }}
            >
              <table id="tires-table" width="100%" style={{ minHeight: 20 }}>
                {
                  <tbody>
                    {item.wheels.length > 0 && (
                      <div style={styles.tireRow}>
                        <div style={{ ...styles.tireLabel, width: 100 }}>
                          Photo
                        </div>
                        <div style={styles.tireLabel}>Tire</div>
                        <div style={styles.tireLabel}>Manufacturer</div>
                        <div style={styles.tireLabel}>Size</div>
                        <div style={styles.tireLabel}>Tread</div>
                      </div>
                    )}
                    {(item.wheels || []).map((wheel, index) => (
                      <div key={index} style={styles.tireRow}>
                        <div style={styles.tireContainer}>
                          <img
                            src={
                              wheel.photo ||
                              get(
                                find(item.medias, {
                                  description: wheel.category + ' Wheel',
                                }),
                                'photo'
                              ) ||
                              '../assets/images/tire_placeholder.png'
                            }
                            style={styles.tireImage}
                            onError={e => {
                              e.currentTarget.onerror = null;
                              e.target.src = urlsMap.get('IMAGE_ERROR_DARK');
                            }}
                            alt=""
                          />
                        </div>
                        <div style={styles.tireValue}>
                          {wheel.category || placeholder}
                        </div>
                        <div style={styles.tireValue}>
                          {wheel.manufacturer || placeholder}
                        </div>
                        <div style={styles.tireValue}>
                          {wheel.size || isZero(wheel.size)
                            ? wheel.size + '"'
                            : placeholder}
                        </div>
                        <div style={styles.tireValue}>
                          {wheel.depth || isZero(wheel.depth)
                            ? wheel.depth + '"'
                            : placeholder}
                        </div>
                      </div>
                    ))}
                  </tbody>
                }
              </table>
            </div>
          </div>

          <div style={styles.sectionContainer}>
            <div style={styles.sectionHeader}>Condition Items</div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                minHeight: 20,
                paddingBottom: 5,
              }}
            >
              <table id="cr-table" width="100%" style={{ minHeight: 20 }}>
                {(item.conditionItems || []).length > 0 && (
                  <div style={{ marginBottom: 10 }}>
                    <thead>
                      <tr>
                        {[
                          'Inspection Date',
                          'Description',
                          'Material Estimate',
                          'Labor Hours',
                          'Labor Estimate',
                          'Paint Hours',
                          'Paint Estimate',
                          'Sublet Estimate',
                          'Total',
                        ].map((item, index) => (
                          <th
                            key={index}
                            rowSpan="1"
                            colSpan={item === 'Description' ? 2 : 1}
                            style={styles.colHeaders}
                          >
                            <strong>{item}</strong>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {(item.conditionItems || []).map((cr, index) => {
                        const descriptionText = [
                          cr.areaDescription,
                          cr.partDescription,
                          cr.conditionDescription,
                          cr.severityDescription,
                          cr.note,
                          cr.description,
                        ].filter(item => item);

                        return (
                          <tr key={index} style={{ textAlign: 'center' }}>
                            <td
                              style={{ textAlign: 'center' }}
                              rowSpan="1"
                              colSpan="1"
                              headers="h22"
                            >
                              {makeReadableDate(
                                item.inspection.time,
                                'M/D/YYYY'
                              )}
                            </td>

                            <td
                              rowSpan="1"
                              colSpan="2"
                              headers="h23"
                              style={{ textAlign: 'center' }}
                            >
                              {descriptionText.join(' - ')}
                            </td>

                            <td
                              rowSpan="1"
                              colSpan="1"
                              headers="h26"
                              style={{ textAlign: 'center' }}
                            >
                              {cr.materialEstimate &&
                              !isZero(cr.materialEstimate)
                                ? commafyCurrency_NEW(cr.materialEstimate, 2)
                                : placeholder}
                            </td>
                            <td
                              rowSpan="1"
                              colSpan="1"
                              headers="h26"
                              style={{ textAlign: 'center' }}
                            >
                              {/*Labor Hours */}
                              {cr.laborHours && !isZero(cr.laborHours)
                                ? cr.laborHours
                                : placeholder}
                            </td>
                            <td
                              rowSpan="1"
                              colSpan="1"
                              headers="h26"
                              style={{ textAlign: 'center' }}
                            >
                              {/*Labor Estimate */}
                              {cr.laborEstimate && !isZero(cr.laborEstimate)
                                ? commafyCurrency_NEW(cr.laborEstimate, 2)
                                : placeholder}
                            </td>
                            <td
                              rowSpan="1"
                              colSpan="1"
                              headers="h26"
                              style={{ textAlign: 'center' }}
                            >
                              {/*Paint Hours */}
                              {cr.paintLaborHours && !isZero(cr.paintLaborHours)
                                ? cr.paintLaborHours
                                : placeholder}
                            </td>
                            <td
                              rowSpan="1"
                              colSpan="1"
                              headers="h26"
                              style={{ textAlign: 'center' }}
                            >
                              {/*Paint Estimate */}
                              {cr.paintEstimate && !isZero(cr.paintEstimate)
                                ? commafyCurrency_NEW(cr.paintEstimate, 2)
                                : placeholder}
                            </td>
                            <td
                              rowSpan="1"
                              colSpan="1"
                              headers="h26"
                              style={{ textAlign: 'center' }}
                            >
                              {/*Sublet Estimate */}
                              {cr.subletEstimate && !isZero(cr.subletEstimate)
                                ? commafyCurrency_NEW(cr.subletEstimate, 2)
                                : placeholder}
                            </td>
                            <td
                              rowSpan="1"
                              colSpan="1"
                              headers="h26"
                              style={{ textAlign: 'center' }}
                            >
                              {/*Row total */}
                              {cr.totalEstimate || isZero(cr.totalEstimate)
                                ? commafyCurrency_NEW(cr.totalEstimate, 2)
                                : placeholder}
                            </td>
                          </tr>
                        );
                      })}

                      {/* TOTAL ESTIMATES */}
                      <tr>
                        <td colSpan="3" style={{ textAlign: 'center' }}>
                          Total Estimate
                        </td>
                        <td>
                          {isNaN(item.materialEstimateTotal)
                            ? '$0.00'
                            : commafyCurrency_NEW(
                                item.materialEstimateTotal ||
                                  this.makeTotal('materialEstimate'),
                                2
                              )}
                        </td>
                        <td>
                          {isNaN(item.laborHoursTotal)
                            ? '0.00'
                            : parseFloat(
                                item.laborHoursTotal ||
                                  this.makeTotal('laborHours')
                              ).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(item.laborEstimateTotal)
                            ? '$0.00'
                            : commafyCurrency_NEW(
                                item.laborEstimateTotal ||
                                  this.makeTotal('laborEstimate'),
                                2
                              )}
                        </td>
                        <td>
                          {isNaN(item.paintHoursTotal)
                            ? '0.00'
                            : parseFloat(
                                item.paintHoursTotal ||
                                  this.makeTotal('paintLaborHours')
                              ).toFixed(2)}
                        </td>
                        <td>
                          {isNaN(item.paintEstimateTotal)
                            ? '$0.00'
                            : commafyCurrency_NEW(
                                item.paintEstimateTotal ||
                                  this.makeTotal('paintEstimate'),
                                2
                              )}
                        </td>
                        <td>
                          {isNaN(item.subletEstimateTotal)
                            ? '$0.00'
                            : commafyCurrency_NEW(
                                item.subletEstimateTotal ||
                                  this.makeTotal('subletEstimate'),
                                2
                              )}
                        </td>
                        <td />
                      </tr>

                      {/* GRAND TOTAL */}
                      <tr>
                        <td colSpan="3">Grand Total</td>
                        <td colSpan="7">
                          {isNaN(item.conditionItemsGrandTotal)
                            ? '$0.00'
                            : commafyCurrency_NEW(
                                item.conditionItemsGrandTotal ||
                                  this.makeGrandTotalEstimate(),
                                2
                              )}
                        </td>
                      </tr>
                    </tbody>
                  </div>
                )}
              </table>
            </div>
          </div>

          {/* PICTURES */}
          <div style={styles.sectionContainer}>
            <div style={styles.sectionHeader}>Pictures</div>
            <div style={styles.picturesContent}>
              {pictures.map((med, index) => (
                <div key={index} style={styles.picturesImageContainer}>
                  <img
                    style={styles.picturesImage}
                    src={med.photo}
                    onClick={() => {
                      this.setState(
                        { showGallery: true, startIndex: index },
                        () => {
                          this.galleryRef.fullScreen();
                        }
                      );
                    }}
                    onError={e => {
                      e.currentTarget.onerror = null;
                      e.target.src = urlsMap.get('IMAGE_ERROR_DARK');
                    }}
                    alt=""
                  />
                  <div style={styles.pictureDescription}>
                    {med.description || med.partDescription || ''}
                  </div>
                </div>
              ))}

              {showGallery && (
                <ImageGallery
                  ref={el => (this.galleryRef = el)}
                  items={pictures.map(
                    ({ photo, description, partDescription }, index, list) => ({
                      original: photo
                        ? photo.replace(/^http:\/\//i, 'https://')
                        : '',
                      thumbnail: photo
                        ? photo.replace(/^http:\/\//i, 'https://')
                        : '',
                      description:
                        description ||
                        partDescription ||
                        `${index + 1} of ${list.length}`,
                    })
                  )}
                  onErrorImageURL={urlsMap.get('IMAGE_ERROR_DARK')}
                  showThumbnails={true}
                  showPlayButton={true}
                  showNav={true}
                  showFullscreenButton={true}
                  slideInterval={2500}
                  lazyLoad
                  startIndex={startIndex}
                  onScreenChange={changed => {
                    this.setState({ showGallery: changed });
                  }}
                  renderFullscreenButton={(onClick, isFullscreen) => (
                    <Fullscreen
                      onClick={onClick}
                      isFullscreen={isFullscreen}
                      callback={() => {
                        this.galleryRef.exitFullScreen();
                        this.setState({ showGallery: false, startIndex: 0 });
                      }}
                    />
                  )}
                />
              )}
            </div>
          </div>

          {/* BUILD DATA */}
          <div style={styles.sectionContainer}>
            <div style={styles.sectionHeader}>Build Data</div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                minHeight: 20,
                padding: 5,
              }}
            >
              <div style={styles.labelValueContainerHorizontal}>
                <div style={styles.horizontalLabel}>Sale Date:</div>
                <div style={styles.horizontalValue}>
                  {makeReadableDate(item.auction.saleDate)}
                </div>
              </div>

              <div style={styles.labelValueContainerHorizontal}>
                <div style={styles.horizontalLabel}>Stock:</div>
                <div style={styles.horizontalValue}>
                  {item.auction.stockNumber}
                </div>
              </div>

              <div style={styles.labelValueContainerHorizontal}>
                <div style={styles.horizontalLabel}>Lot No:</div>
                <div style={styles.horizontalValue}>
                  {item.auction.lotNumber}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConditionReportConsolidated.propTypes = {
  itemData: PropTypes.object,
  link: PropTypes.string,
};

ConditionReportConsolidated.defaultProps = {
  itemData: {},
};

export default ConditionReportConsolidated;
