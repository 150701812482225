import React from 'react';
import { getConfig } from '../../utils/helpers';

const styles = {
  linkStyle: {
    textDecoration: 'underline',
    color: '#787878',
    wordBreak: 'break-word',
  },
  holmanHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 40,
    marginBottom: 20,
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh',
    maxWidth: 560,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 15,
  },
  tableBody: {
    width: '100%',
  },
  tableRow: {
    display: 'flex',
    minHeight: 48,
  },
  tableData: {
    padding: 4,
    border: '1px solid #787878',
    width: '50%',
    borderBottom: 'none',
  },
};

export const makeLink = (label, link) => (
  <a href={link} target="_blank" rel="noreferrer" style={styles.linkStyle}>
    {label}
  </a>
);

export const makeMailLink = (label, email) => (
  <a href={`mailto:${email || label}`} style={styles.linkStyle}>
    {label}
  </a>
);

// FAQ
// if a customer wants their own external FAQ link add it to the config: "faqLink": "https://some_faq_link.com"
// if a customer wants to hide the FAQ links add it to the config as null:  "faqLink": null
// if a customers wants their own Q & A's add them to the faqList below using their marketplace id (e.g. Holman).
// you have the ability to make sections, hyperlinks, email links, and line breaks -- see the examples
// to show the FAQ link which shows the standard Q and A's don't do anything as it's the default behavior

export const makeLineBreak = (height = 10) => <div style={{ height }} />;

const faqList = {
  540: [
    {
      question: 'Who is Holman?',
      answer: [
        `Holman consists of exceptional people delivering fleet leasing and management services,
                  van and truck productivity solutions, vehicle fabrication and upfitting, powertrain component
                  distribution, automotive retail sales and service, insurance and risk mitigation, and
                  automotive-centric innovative solutions. Equipment Direct is facilitated by Holman's Fleet
                  and Mobility Division. `,
        makeLink(
          'AED Buyer Standard Operating Procedures',
          'https://iasmp.s3.amazonaws.com/holman.iasmarketplace.com/buyer_sop.pdf'
        ),
      ],
    },

    {
      question: `What is Equipment Direct?`,
      answer: `Equipment Direct is a Virtual marketplace that transacts the exchange of Equipment, Trailer
                  and Medium/Heavy Truck assets between Sellers and Buyers through a Reserve based Competitive Bidding Auction`,
    },
    {
      question: 'When are Auctions held?',
      answer: `Every Thursday starting at 12:00 pm to Friday ending at 12:00 pm (24 Hour Sale).`,
    },
    {
      question: 'What is Bid Extension Mode?',
      answer: `Bid Extension occurs when a buyer places a bid within the last 2 minutes of the end of the
                  sale. This action sets off an extended bidding time frame with 2 minute count down intervals. 
                  Countdown time may display a value larger than 2 minutes and this is due to a more "seamless" 
                  transition from regular auction time period to bid extension time period`,
    },
    {
      question: 'How should Tax Exempt Buyers handle signup?',
      answer: [
        'Tax exempt buyers should follow all normal signup but should email their Tax Exempt ID form to ',
        makeMailLink('EquipmentDirectSales@holman.com'),
        'and place "Equipment Direct Tax Exempt ID Form" in the subject line',
      ],
    },
    {
      question: 'How are same price proxy bids handled?',
      answer: `Proxy Bids placed at the same amount from 2 different purchasers ultimately result in the high bid 
            being awarded to the buyer who placed said proxy bid first`,
    },
    {
      question: 'How do I know that I am the high bidder?',
      answer:
        'You will receive an automated email notifying that you are the high bidder once the bidding time has concluded',
    },
    {
      question: 'How and when will I know if my high bid was accepted?',
      answer: `You will received a "bid acceptance" email indicating your bid was accepted and we strive to
                  have same day answers made and we set a 12pm deadline the following day to make all selling decisions`,
    },
    {
      question: 'How are Buyer - Seller disputes handled?',
      answer: `Equipment Direct is an "AS-IS" Marketplace without a formal set of arbitration procedures.
                  At the time of signup, sellers are presented Best Practice recommendations regarding both
                  full disclosure of condition and the consideration of post-sale gross mis-representation
                  situations presented by our buyers. Buyer should expect refund and concession payments
                  10 calendar days after the agreed action plan is set`,
    },
    {
      question: 'Who do I contact for Pre-Sale and Sale Day related inquiries',
      answer: makeMailLink('EquipmentDirectSales@holman.com'),
    },
    {
      question: 'Who do I contact for Payment related inquiries?',
      answer: makeMailLink('EquipmentDirectPayments@holman.com'),
    },
    {
      question: 'Who do I contact for Release/Retrieval related inquiries?',
      answer: makeMailLink('equipmentdirectretrievals@holman.com'),
    },
    {
      question:
        'What timeframe do I have to remit payment on assets I purchased?',
      answer: `Payment must be remitted in 7 calendar days from date of invoice. Consistent failure to
                  meet this timeframe will result in loss of authorization to participate in Equipment Direct Auction`,
    },
    {
      question: 'What forms of payment are accepted?',
      answer: `ACH (recommended), Wire Transfer, Certified Check, Business Check, Personal Check.
                  Business and Personal Checks require a 10 day waiting period for funds to clear prior to release and are strongly discouraged`,
    },
    {
      question: 'How should we notify Holman of payments submitted?',
      answer: [
        `Please email your payment info (check, wire or ach info) and a copy of the invoice you are
                  paying to `,
        makeMailLink(
          'EquipmentDirectPayments@holman.com. ',
          'EquipmentDirectPayments@holman.com'
        ),
        `Since not all received payments include reference to assets being sold, failure to notify us could 
            result in longer than usual release times`,
      ],
    },
    {
      question:
        'How long does it take Holman to process payment and produce a release?',
      answer:
        'Our team processes releases on sales with funds fully received within 48 hours',
    },
    {
      question:
        'What documentation is required for buyers to complete to finalize a purchase?',
      answer:
        'For titled assets, an Odometer Statement is provided with the invoice and required to be completed',
    },
    {
      question: 'What timeframe do I have to secure assets?',
      answer: [
        `Assets should be secured within 10 calendar days from the "Release Date" and our team
            verifies retrieval status directly with client locations. Any Retrieval related issues, hurdles
            and obstacles should be presented to `,
        makeMailLink('equipmentdirectretrievals@holman.com'),
      ],
    },
    {
      question: 'What is expected of sellers during retrieval?',
      answer: `Sellers must do the following: 1. Maintain reasonable available hours at the grounding
                  location 2. Ensure asset is not physically obstructed from removal 3. Ensure, along with
                  transporter, that the proper asset is released by matching release numbers 4. Sellers are
                  NOT responsible for or required to load or maneuver assets into an "idea" area albeit they
                  can assist if possible`,
    },
    {
      question: 'What is expected of buyers during retrieval?',
      answer: `Buyers must do the following: 1. Give location advanced notice 2. Ensure transporter has
                  proper equipment in the event location is unable to assist with loading 4. Ensure correct
                  asset is retrieved by confirming serial number and matching release #`,
    },
    {
      question:
        'What is the Buyer Fee Schedule for purchases made through Equipment Direct?',
      answer: (
        <table className="fee-table" style={{ margin: 10, marginLeft: 0 }}>
          <tbody>
            <tr>
              <th colSpan="2" style={{ textAlign: 'center' }}>
                Equipment Direct Buyer Fee Schedule - 2025
              </th>
            </tr>
            <tr>
              <th style={{ textAlign: 'left' }}>Sale Price Range</th>
              <th>Fee</th>
            </tr>
            {[
              ['$0', '$499', '$130'],
              ['$500', '$1,499', '$195'],
              ['$1,500', '$2,999', '$295'],
              ['$3,000', '$4,999', '$345'],
              ['$5,000', '$7,499', '$395'],
              ['$7,500', '$9,999', '$445'],
              ['$10,000', '$14,999', '$495'],
              ['$15,000', '$19,999', '$595'],
              ['$20,000', '$24,999', '$645'],
              ['$25,000', '$29,999', '$695'],
              ['$30,000', '$39,999', '$845'],
              ['$40,000', '$49,999', '$995'],
              ['$50,000', '$74,999', '$1,295'],
              ['$75,000', '$99,999', '$1,395'],
              ['$100,000+', null, '$1,595'],
            ].map(([min, max, fee], index) => (
              <tr key={index}>
                <td style={{ textAlign: 'left' }}>
                  <span style={{ border: 'none' }}>{min}</span>
                  {max && <span> - </span>}
                  {max && <span>{max}</span>}
                </td>
                <td>{fee}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ),
    },
  ],
  DEFAULT: [
    {
      section: 'GENERAL',
    },
    {
      question: 'What are the fees to participate on the Marketplace?',
      answer: `A: Vehicles that are bought and sold through the Marketplace are subject to a sale fee for the seller 
          and buy fee for the buyer, pursuant to the rules of the host auction. Additional fees, 
          such as post-sale inspections or transportation are figured at the auction level and stated in that auctions rules and regulations.`,
    },

    {
      question: 'What is a Proxy bid and a Hard bid?',
      answer: [
        `A: A proxy is the maximum amount you are willing to pay as a buyer.
          The number is encrypted and cannot be seen by the auction, auctioneers, other sellers or buyers.
          The proxy engine only raises to your maximum amount as you are out-bid and will not exceed your highest proxy amount.  `,
        makeLineBreak(),
        `A: A hard bid represents the intended amount you are willing to bid.
            It takes you directly to the number you bid regardless of any other bids below.`,
      ],
    },
    {
      question: 'How does the Live Simulcast work?',
      answer: `A: Live auction simulcast will appear as an option on the auction screen for any vehicle to which it applies.
          The simulcast will give you a LIVE video feed of the auction as it happens and will allow you to actively participate
          in the bidding process in REAL time.`,
    },
    {
      question: 'How do I check for sound connection?',
      answer: `A: Check your internal speakers and that the volume is turned up.
          There is also a speaker icon in the Live Simulcast and HyperLane console.`,
    },
    {
      question: 'I missed an alert that popped up, how do I find it?',
      answer: `A: Reference the Activity Timeline or Notification Bell.`,
    },
    {
      section: 'BUYERS',
    },
    {
      question: 'How do I know if I won a vehicle I have previously bid on?',
      answer: `A: You will be alerted if you are outbid or if you are the highest bidder on a vehicle.
          You can click on "Activity Tracker" and Items I have Purchased buyer tab on the dashboard.`,
    },
    {
      question: `What happens if I'm interrupted during the bidding or buying process?`,
      answer: `A:  In the event that a buyer’s personal internet service connection to the internet
          (over which Marketplace has no control or influence) is interrupted,
          the last bid received would be valid and if defined a Maximum Proxy Bid,
          Marketplace will automatically and incrementally submit bids until you are the winner or reach your Maximum Proxy Bid.`,
    },
    {
      question:
        'How do I get the confirmation paperwork on a vehicle I have purchased?',
      answer: `A: The host Auction will email a confirmation of what you bought or sold based on the information provided on your profile.`,
    },
    {
      question: 'Who do I pay for the vehicle?',
      answer: `A: Your Host Auction manages all financial and title activities related to the purchase.`,
    },
    {
      question: 'Can I use my floorplan company?',
      answer: `A: Yes, provided host auction accepts select floorplan companies. Confirm your floorplan sources with your Auction.
          At the conclusion of the bidding, you will be given the opportunity to request how you would like to pay.`,
    },
    {
      question:
        'Can I make payment for purchased vehicles on the the Marketplace?',
      answer: `A: No. See the Terms and Conditions of the Marketplace. Contact your Auction for payment processing requirements.`,
    },
    {
      question:
        'How does Arbitration/Announcement Lights work in the Marketplace?',
      answer: [
        `A: Your Auction Marketplace subscribes to NAAA Arbitration guidelines. See rules and regulations for arbitrations on the terms and conditions page. See Arbitration Rules provided by NAAA including Announcement Lights and other Auction guidelines: `,
        makeLink(
          'NAAA Guidelines',
          'https://www.naaa.com/Policy/Policy_PDFs/2017_ArbitrationPolicy_Effective_4_17_17/NAAA_Arbitration_Policy_April_17_2017_FINAL.pdf'
        ),
      ],
    },
    {
      question: 'What does the stoplight icon mean on a vehicle?',
      answer: `A: The stoplight is the light structure defined in the Marketplace auction rules.`,
    },
    {
      question: 'How do I file an Arbitration?',
      answer: `A: All arbitrations must be filed directly with the arbitration manager at YOUR AUCTION.`,
    },
    {
      question:
        'What time frame do I have to Arbitrate a vehicle I buy in the Marketplace?',
      answer: `A: Your Host Auction adheres to NAAA arbitration guidelines. Please ask your Auction for more information.`,
    },
    {
      question: 'What is the importance of the Watchlist?',
      answer: `A: The Watchlist allows you to track the sale price as well as place a bid at (a later date). Any status changes
          (de-listing, sold, sold on an “IF”, or moved to another event) will be reflected in your Watchlist.`,
    },
    {
      question: 'How long does a vehicle stay on your Watchlist?',
      answer: `A: A vehicle will stay on your watchlist after  being sold or removed unless you check the remove from watchlist button
          on the vehicle card. Vehicles are automatically added to your watch list when you place a bid from anywhere in the Marketplace.`,
    },
    {
      question:
        'If I remove a vehicle from a watchlist, how can I find the vehicle again?',
      answer: `A: If you remove a vehicle from the watch list and that vehicle has not sold you need to search the inventory to find it
          or you can try to look for the vehicle notification in the Activity Timeline.`,
    },
    {
      question:
        'I am waiting on a car to come through a lane, how will I know if the vehicle runs through a lane if I am watching another lane?',
      answer: `A: If you have the Watchlist Alert enabled in your profile alerts, any vehicle on your watch list or that you have bid on
          triggers an alert 3-5 vehicles before the vehicle approaches the block, simultaneously highlighting the vehicle on the upcoming vehicles list.`,
    },
    {
      question: 'What details does the QR code provide?',
      answer: `A: The QR code on the vehicle details page is a representation of the vehicle's VIN.
          This QR code allows 3rd party VIN explosions and vehicle history information.`,
    },
    {
      question:
        'Can I connect to CARFAX or AutoCheck vehicle information in the Marketplace?',
      answer: `A: Yes. Once you have loaded your username and password it will save in the system for future use.`,
    },
    {
      question:
        'If I buy a vehicle through the Marketplace, am I responsible to transport my vehicle?',
      answer: `A: Yes, it is the buyer's responsibility to cover transportation fees from the seller's location or from the Auction.`,
    },
    {
      question:
        'Am I required to use the transportation companies affiliated with the Auction or can I use my own?',
      answer: `A: You may choose any method of transportation. It is important to note that the transport companies available
          through the Auction can assist in managing the logistics.
          However, if you choose any other company or method of pick-up, please be aware that you are responsible for the condition
          of the vehicle from the time it is picked up from the Seller's lot.`,
    },
    {
      section: 'SELLERS',
    },
    {
      question: 'As a Seller, how do I consign a vehicle to the Marketplace?',
      answer: `A: Your vehicles can easily be uploaded to the Marketplace directly from the Marketplace/Auction's host applications
          and Dealer Marketplace Events, including third party vendor feeds. Potentially, CoRe, a downloadable application,
          can provide users the ability to produce a condition report and post to the Marketplace with the assistance from the Auction.
          The seller's Activity Section details the reserve price and any disclosures provided by you and the Auction.`,
    },
    {
      question:
        'As a Seller, can I update vehicle pricing after an offer has been made?',
      answer: `A: Yes. As a seller, the "Activity Section" can manage the price and additional description.
          If the description materially alters the car's value e.g. frame, color change, all bidders will be notified and their bids
          may be retracted by the bidder(s)`,
    },
    {
      question:
        'After I sell my vehicle from my dealership, is there anything I should do in order to get the vehicle to the buyer?',
      answer: `A: Yes, it is the seller's responsibility to have the vehicle ready for immediate transport.
          The buyer initiates transport with the company they desire, and the transport company will handle the transportation of the vehicle.
          If the sold vehicle becomes unavailable to the purchaser, the seller will potentially be charged a $500 fee that will go to the
          purchasing dealer.`,
    },
    {
      question:
        'What should I do if I retail a vehicle that is listed on your Marketplace?',
      answer: `A: You are responsible for removing the vehicle from your Listed inventory in the Seller Activity Section or inform the
          Marketplace Sales Staff to help you remove the vehicle.`,
    },
    {
      question: 'How will I get paid for cars I sell?',
      answer: `A: All Sales are processed through your Host Auction once the title is processed.`,
    },
    {
      question: 'Where do I send my title?',
      answer: `A: Titles are sent to Your Host Auction.`,
    },
    {
      question: 'Who will the buying dealer receive the title from?',
      answer: `A: The host auction will forward the title(s) directly to the buying dealership.`,
    },
    {
      question: 'Who pays for transportation?',
      answer: `A: The buyer selects the method of transportation.
          The transportation expense becomes the responsibility of the buyer once the vehicle is purchased.
          In the event the vehicle is returned to the Seller, the Seller would be responsible for transportation costs both ways.`,
    },
    {
      section: 'SETUP',
    },
    {
      question: 'What are the minimum system requirements to use your Website?',
      answer: [
        `A: One key feature of our website is that it is fully web-based and does NOT require any specialty programs to be downloaded
            and installed to operate. The best results will be obtained with a typical broadband Internet connection,
            such as DSL or cable, T-1, etc. `,
        <ul>
          {[
            `Pentium III processor or better with 512 MB memory. No Additional Disk Space Required.`,
            `Windows XP or Mac OS 9 or newer. Also supports Windows 7, Vista, X, & Mac OS X.`,
            `Internet Explorer 7.0, Google Chrome, Apple Safari, Mozilla Firefox 3.0 or greater.`,
            `Sun Java Plug-in 1.6 or greater.`,
            `Sound - Integrated sound card.`,
            `Internet Connection – Broadband such as DSL, Cable, T-1, T-3.`,
            `Minimum 1024x768 screen resolution.`,
            `TCP Ports 80, 8700 and 8701 must be opened.`,
            `If you have a proxy server, it should allow non-http traffic through above mentioned ports.`,
          ].map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>,
      ],
    },
    {
      question:
        'Is there any special setup required for Mobile access to the Marketplace?',
      answer: `A: No. All that is needed is a smartphone with a compatible browser. 
          A Marketplace App may be available for easy mobile access to the Marketplace and Auction Lanes. 
          Check with your Auction for access to a Marketplace App.`,
    },
  ],
};

export const faqs = faqList[getConfig('marketplaceId')] || faqList.DEFAULT;

// SUPPORT (aka contact us)
// if a customer wants their own external Support link add it to the config: "supportLink": "https://some_support_link.com"
// if a customer wants to hide the Support links add it to the config as null:  "supportLink": null
// if a customer wants their own UI add it to the customPages list below (e.g. Holman)
// if a customer wants our Support UI but *their*  contact info add it to the contactInfoList below

const customContactPages = {
  540: (
    <div style={styles.table}>
      <h2 style={styles.holmanHeader}>
        <div style={{ marginLeft: 5, marginRight: 5 }}>Holman Equipment</div>
        <div style={{ marginLeft: 5, marginRight: 5 }}>
          Direct Sales Contact Page
        </div>
      </h2>

      <div style={styles.tableBody}>
        <div style={styles.tableRow}>
          <div style={styles.tableData}>Physical Operations</div>
          <div style={{ ...styles.tableData, borderLeft: 'none' }}>
            <div>4001 Leadenhall Road</div>
            <div>Mount Laurel, NJ 08054</div>
          </div>
        </div>

        <div style={styles.tableRow}>
          <div style={styles.tableData}>Auction Technical Issues</div>
          <div style={{ ...styles.tableData, borderLeft: 'none' }}>
            <div>844-579-1007</div>
            <div>{makeMailLink('support@superior-ia.com')}</div>
          </div>
        </div>

        <div style={styles.tableRow}>
          <div style={styles.tableData}>General and Seller Inquiries</div>
          <div style={{ ...styles.tableData, borderLeft: 'none' }}>
            <div>Mike Ryan</div>
            <div>513-386-4923</div>
            <div>{makeMailLink('EquipmentDirectSales@holman.com')}</div>
          </div>
        </div>

        <div style={styles.tableRow}>
          <div style={styles.tableData}>Payment Inquiries</div>
          <div style={{ ...styles.tableData, borderLeft: 'none' }}>
            <div>{makeMailLink('EquipmentDirectPayments@holman.com')}</div>
          </div>
        </div>

        <div style={styles.tableRow}>
          <div style={styles.tableData}>Retrieval Inquiries</div>
          <div style={{ ...styles.tableData, borderLeft: 'none' }}>
            <div>{makeMailLink('equipmentdirectretrievals@holman.com')}</div>
          </div>
        </div>

        <div style={styles.tableRow}>
          <div
            style={{ ...styles.tableData, borderBottom: '1px solid #787878' }}
          >
            Hours
          </div>
          <div
            style={{
              ...styles.tableData,
              borderLeft: 'none',
              borderBottom: '1px solid #787878',
            }}
          >
            Monday - Friday, 8:30am to 5:00pm ET
          </div>
        </div>
      </div>
    </div>
  ),
};

export const customContactPage = customContactPages[getConfig('marketplaceId')];
