import React from 'react';
import { connect } from 'react-redux';
import Vehicle from '../Vehicle';

const GridItem = ({ bemBlocks, result, bidMessagesRealTime, showVehicleValuesButton }) => {
  const source = {
    ...result._source,
    ...result.highlight,
    ...result.fields,
  };

  const itemData = {
    ...source,
    ...bidMessagesRealTime[source.id],
  };

  return (
    <div
      key={itemData.id}
      className={bemBlocks.item().mix(bemBlocks.container('item'))}
      data-qa="hit"
      style={{ height: '100%', margin: 18 }}
    >
      <Vehicle 
        key={itemData.id} 
        category="search" 
        vehicleData={itemData} 
        showVehicleValuesButton={showVehicleValuesButton} />
    </div>
  );
};

const mapStateToProps = state => {
  const { bidMessagesRealTime } = state.entities;
  return { bidMessagesRealTime };
};

export default connect(
  mapStateToProps,
  {}
)(GridItem);
