import React from 'react';
import { connect } from 'react-redux';
import VehicleListItem from '../VehicleListItem';

const ListItem = ({ bemBlocks, result, bidMessagesRealTime, showVehicleValuesButton }) => {
  const source = {
    ...result._source,
    ...result.highlight,
    ...result.fields,
  };

  const itemData = {
    ...source,
    ...bidMessagesRealTime[source.id],
  };

  return (
    <div
      key={itemData.id}
      className={bemBlocks.item().mix(bemBlocks.container('item'))}
      data-qa="hit"
      style={{}}
    >
      <VehicleListItem
        key={itemData.id}
        category="search"
        vehicleData={itemData}
        showVehicleValuesButton={showVehicleValuesButton}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const { bidMessagesRealTime } = state.entities;
  return { bidMessagesRealTime };
};

export default connect(
  mapStateToProps,
  {}
)(ListItem);
